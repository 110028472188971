import React, { useContext, useEffect, useState } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { api } from "../../API";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../../AppContext/Context";
import Loader from "../loader";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import SweetLayout from "../../Layout/SweetLayout";

const Companies = () => {
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [search, setSearch] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const { setIsLoad, isLoad } = useContext(AppContext);
  const handleUnauthorized = useHandleUnauthorized();

  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoad(true);
    setSearchPerformed(true);

    try {
      const getIdToken = await getIdTokenClaims();
      const idToken = getIdToken.__raw;
      const payload = { data: { company_name: search } };

      const response = await api.post("/admin/v1/start_company", payload, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      if (response.data && Object.keys(response.data).length > 0) {
        setCompanyData(response.data);
      } else {
        setCompanyData(null);
      }
    } catch (error) {
      console.error("Error:", error);
      setCompanyData(null);
      handleUnauthorized(error);
    } finally {
      setIsLoad(false);
    }
  };
  return (
    <SweetLayout>
    <div>
      {showErrorMessage ? (
        <div
          className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          You are not authorized to use this app. Please contact your ept AI
          administrator if you believe you should have access.
        </div>
      ) : (
        <div>
          <h1>Companies</h1>
          <div style={{ width: "300px" }}>
            {isLoad && (
              <div
                style={{
                  position: "absolute",
                  // top: "150px",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // height: "300px",
                  // border: "1px solid red",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Company Name"
                aria-label="Company Name"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
                style={{maxWidth: '300px'}}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={(e) => handleSubmit(e)}
              >
                Run
              </Button>
            </InputGroup>
          </div>
          <hr />
          <div>
            {searchPerformed && companyData === null && (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "white",
                  padding: "20px",
                }}
              >
                <h3>No such Company found</h3>
              </div>
            )}
            {companyData && (
              <>
                <h3>Companies Data:</h3>
                <div style={{ backgroundColor: "white", padding: "10px" }}>
                  {Object.entries(companyData).map(([key, value]) => (
                    <p key={key}>
                      <strong>{key}:</strong> {value}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
    </SweetLayout>
  );
};

export default Companies;
