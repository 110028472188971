import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

const useHandleUnauthorized = () => {
  const { logout } = useAuth0();

  const handleUnauthorized = useCallback((error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      logout({ logoutParams: { returnTo: window.location.origin } });
      // Force page reload to clear state
      window.location.reload();
    }
  }, [logout]);

  return handleUnauthorized;
};

export default useHandleUnauthorized;
