import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { convertTimeStampToUnix } from "../../common/Functions";
import SettingsLoader from "../../common/SettingsLoader";

function ReportingFilter(props) {
    const { setStartDate, setEndDate, setRange, range, loading } = props;
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleDateSelection = (ranges) => {
        const { selection } = ranges;
        setState([selection]);
        setRange(null);
        let startDate = new Date(ranges.selection.startDate);
        let endDate = new Date(ranges.selection.endDate);

        // Check if start date and end date are the same
        if (startDate.getTime() === endDate.getTime()) {
            // Add 23 hours and 59 minutes
            endDate = new Date(endDate.getTime() + ((23 * 60 + 59) * 60 + 59) * 1000);
        }
        setStartDate(convertTimeStampToUnix(startDate));
        setEndDate(convertTimeStampToUnix(endDate));
    };
  useEffect(() => {
    function handleClick(e) {
      e.preventDefault();
      let ele = e.target;

      if (
        ele.className !== "toggle-date-picker" &&
        ele.closest(".date-picker-box") === null
      ) {
        setShowDatePicker(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleRange = (range) => () => {
    setRange(range);
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <div className="chart-filter-wrap">
      <div className="chart-filter-btn-wrap">
        {loading && (
          <div className="chart-loading">
            <SettingsLoader />
          </div>
        )}
        <button
          className={`${range === 30 ? "active" : ""}`}
          onClick={handleRange(30)}
        >
          Last 30 Days
        </button>
        <button
          className={`${range === 60 ? "active" : ""}`}
          onClick={handleRange(60)}
        >
          Last 60 Days
        </button>
        <button
          className={`${range === 90 ? "active" : ""}`}
          onClick={handleRange(90)}
        >
          Last 90 Days
        </button>
      </div>
      <div className="filters-input">
        <div className="date-picker-wrap">
          <div className="toggle-date-picker" onClick={toggleDatePicker}></div>
          {showDatePicker && (
            <div className="date-picker-box">
              <DateRange
                editableDateInputs={true}
                onChange={handleDateSelection}
                moveRangeOnFirstSelection={false}
                ranges={state}
                months={1}
                rangeColors={["#053F98"]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportingFilter;
