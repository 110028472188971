import React from "react";

const FetchDataMessage = ({style}) => {
  return (
    <div>
      <div
        className="border border-dark d-flex align-items-center justify-content-center fw-bold"
        style={style}
      >
        Fetching data, this may take a few moments...
      </div>
    </div>
  );
};

export default FetchDataMessage;
