import React, { useContext } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import "./App.css";
import AppRouter from "./router/AppRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AppContext } from "../src/AppContext/Context";
import { auth0ClientId, auth0Domain } from "./config";

function App() {
  const { isOpen, setIsOpen, isLoad } = useContext(AppContext);
  const client_id = auth0ClientId();
  const domain = auth0Domain();
  return (
    <Auth0Provider
      domain={domain}
      clientId={client_id}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}>
      <AppRouter />
    </Auth0Provider>
  );
}

export default App;
