import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext/Context";
import SweetLayout from "../../Layout/SweetLayout";

const UserTabDetails = () => {
  const nav = useNavigate();
  const { data } = useContext(AppContext);

  const navigateToAccountDetails = (accountId) => {
    nav(`/account/${accountId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };

  const avatarStyle = {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  };

  return (
    <SweetLayout>
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center">
        <div
          onClick={() => nav(-1)}
          className="fe-5 fw-bold"
          style={{ cursor: "pointer" }}
        >
          <span className="bi bi-chevron-left"></span> Back
        </div>
      </div>
      <Card className="mt-2">
        <div className="p-4">
          <div className="fw-bold fs-3">Personal Info:</div>
          <div className="mt-1 p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <div className="">
                <img
                  style={avatarStyle}
                  src={data?.picture}
                  alt="Please wait..."
                />
              </div>
              <div className="">
                <span className="fw-bold">
                  {data?.nickname ? data?.nickname : "N/A"}
                </span>
                <div className="fw-light">
                  {data?.email ? data?.email : "N/A"}
                </div>
              </div>
            </div>
            <div className="">
              <span className="fw-bold fs-5">Account ID: </span>
              <span className="fs-5 text-primary">
                <a
                  style={{ textDecoration: "none" }}
                  href={`/account/${data?.account_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToAccountDetails(data?.account_id);
                  }}
                >
                  {data?.account_id ? data?.account_id : "N/A"}
                </a>
              </span>
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Nickname</div>
            <div className="fw-light">
              {data?.nickname ? data?.nickname : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Email account</div>
            <div className="fw-light">{data?.email ? data?.email : "N/A"}</div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Created At</div>
            <div className="fw-light">
              {data?.created_at ? formatDate(data?.created_at) : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Updated At</div>
            <div className="fw-light">
              {data?.updated_at ? formatDate(data?.updated_at) : "N/A"}
            </div>
          </div>
        </div>
      </Card>
      <Card className="mt-2">
        <div className="p-4">
          <div className="fw-bold fs-3">User Info:</div>
          <div className="p-2 mt-1 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Last IP</div>
            <div className="fw-light">
              {data?.last_ip ? data?.last_ip : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Last Login</div>
            <div className="fw-light">
              {data?.last_login ? formatDate(data?.last_login) : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Last Password Reset</div>
            <div className="fw-light">
              {data?.last_password_reset
                ? formatDate(data?.last_password_reset)
                : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Login Count</div>
            <div className="fw-light">
              {data?.logins_count ? data?.logins_count : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">User ID</div>
            <div className="fw-light">
              {data?.user_id ? data?.user_id : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Tenant</div>
            <div className="fw-light">
              {data?.tenant ? data?.tenant : "N/A"}
            </div>
          </div>
        </div>
      </Card>
      <Card className="mt-2">
        <div className="p-4">
          <div className=" fw-bold fs-3">Identities:</div>
          <div className="p-2 mt-1 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Connection</div>
            <div className="fw-light">
              {data?.identities?.[0]?.connection
                ? data?.identities[0].connection
                : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Is Social</div>
            <div className="fw-light">
              {data && typeof data?.identities?.[0]?.isSocial === "boolean"
                ? data?.identities?.[0]?.isSocial
                  ? "True"
                  : "False"
                : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">Provider</div>
            <div className="fw-light">
              {data?.identities?.[0]?.provider
                ? data?.identities[0].provider
                : "N/A"}
            </div>
          </div>
          <div className="p-1">
            <hr />
          </div>
          <div className="p-2 d-flex gap-3 justify-content-between align-items-center">
            <div className="fw-bold">User ID</div>
            <div className="fw-light">
              {data?.identities?.[0]?.user_id
                ? data?.identities[0].user_id
                : "N/A"}
            </div>
          </div>
        </div>
      </Card>
    </div>
    </SweetLayout>
  );
};

export default UserTabDetails;
