import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Table, Row, Col, Accordion, Badge, Container } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import Form from "react-bootstrap/Form";
import debounce from "lodash/debounce";
import Button from "react-bootstrap/Button";
import Loader from "../loader";
import { api } from "../../API";
import Select from "react-select";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import SweetLayout from "../../Layout/SweetLayout";

const UserTab = () => {
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { setIsLoad, isLoad, data, setData } = useContext(AppContext);
  // const [data, setData] = useState();
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const [editModeUser, setEditModeUser] = useState(false);
  const [editableUser, setEditableUser] = useState(false);
  const [filter, setFilter] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [isEditRoleDone, setIsEditRoleDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const nav = useNavigate();
  const location = useLocation();
  const handleUnauthorized = useHandleUnauthorized();
  const [selectedRole, setSelectedRole] = useState(null);
  const inputEmailSearch = data?.email;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    if (emailParam) {
      const sanitizedEmail = emailParam.replace(/\s/g, "+");
      const decodedEmail = decodeURIComponent(sanitizedEmail);
      setFilter(decodedEmail);
      setShowTable(true);
    }
  }, [location.search]);
  useEffect(() => {
    let preSelectedOptions = [];
    preSelectedOptions = data?.groups
      ?.filter((option) => option === "Admin" || option === "User")
      .map((groupName) => ({
        value: groupName,
        label: groupName,
      }));

    setSelectedRole(preSelectedOptions);
  }, [data]);

  const handleRoleChange = (selectedOption) => {
    const allowedOptions = selectedOption.filter(
      (option) => option.value === "User" || option.value === "Admin"
    );
    setSelectedRole(allowedOptions);
  };
  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  const options = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
  ];

  const selectedRoleValues = selectedRole?.map((option) => option.value);
  useEffect(() => {
    if (showTable) {
      const controller = new AbortController();
      setAbortController(controller);

      const fetchData = async () => {
        if (!isLoading) {
          setIsLoad(true);
          const bodyData = {
            get_account_info: false,
          };
          const endpoint = `${config.baseURL}/admin/v1/user/${filter}`;

          try {
            setIsError(false);
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;

            const response = await axios.get(endpoint, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
              params: bodyData,
              signal: controller.signal,
            });
            Object.keys(response.data).length === 0
              ? setIsError(true)
              : setIsError(false);
            setData(response.data);
          } catch (error) {
            if (error.name === "AbortError") {
              // console.log("Previous request aborted");
            } else {
              console.error("API request error:", error.message);
              setIsError(true);
              handleUnauthorized(error);
            }
          } finally {
            setIsLoad(false);
          }
        }
      };

      const debouncedFetchData = debounce(fetchData, 2000);

      debouncedFetchData();

      return () => {
        debouncedFetchData.cancel();
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [isLoading, filter, isEditRoleDone, handleUnauthorized]);

  const apiEmail = data?.email ? data.email : "";
  const handleEditUser = () => {
    setEditModeUser(true);
    setEditableUser(true);
  };

  const handleSaveUser = async () => {
    setEditModeUser(false);
    setEditableUser(false);
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = {
        groups: selectedRoleValues,
      };
      await api.put(`${config.baseURL}/admin/v1/user/${apiEmail}`, payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsEditRoleDone(!isEditRoleDone);
      setIsLoad(false);
      setSelectedRole([]);
    } catch (error) {
      console.error("Error:", error);
      setIsLoad(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };

  // useEffect(() => {
  //   if (user && user.ept_roles) {
  //     setTimeout(() => {
  //       if (
  //         !user.ept_roles.includes("EptAdmin") &&
  //         !user.ept_roles.includes("EptViewer")
  //       ) {
  //         setShowErrorMessage(true);
  //       } else {
  //         setShowErrorMessage(false);
  //       }
  //     }, 2000);
  //   }
  // }, [user, isAuthenticated, isLoading]);
  return (
    <SweetLayout>
    <div>
      {showErrorMessage ? (
        <div
          className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          You are not authorized to use this app. Please contact your ept AI
          administrator if you believe you should have access.
        </div>
      ) : (
        <div>
          <Row className="mb-1 d-flex">
            <Col xs={12} sm={12} md={6} className="mt-1">
              <div className="d-flex gap-3 align-items-center">
                <h1>User</h1>
                <Form.Control
                  size="lg"
                  type="search"
                  style={{ maxWidth: "300px" }}
                  placeholder="Search Email"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setIsError(false);
                      setShowTable(false);
                    } else {
                      setShowTable(true);
                    }
                    setFilter(e.target.value);
                  }}
                  defaultValue={inputEmailSearch}
                />
              </div>
            </Col>
            <div className="w-100">
              <hr />
            </div>
          </Row>
          {isError ? (
            <>
              <h3 className="d-flex justify-content-center">No Record found</h3>
            </>
          ) : (
             (
              <Table responsive striped bordered hover className="x-auto">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Roles</th>
                    <th>Email Address</th>
                    <th>Status</th>
                    {showEditButton && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.name}</td>
                    <td style={{ width: "350px", minWidth: "300px" }}>
                      <Accordion activeKey={isAccordionOpen ? "0" : null}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header onClick={handleAccordionToggle}>
                            {data?.groups?.map((value, index, array) => (
                              <>
                                {value}
                                {index !== array.length - 1 && ","}{" "}
                              </>
                            ))}
                          </Accordion.Header>
                          <Accordion.Body>
                            {showEditButton && (
                              <>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="fw-bold fs-6">
                                    Customize Users:
                                  </div>
                                  {showEditButton && !editableUser && (
                                    <Button
                                      variant="primary"
                                      className="d-flex justify-content-center align-items-center gap-2"
                                      onClick={handleEditUser}
                                    >
                                      <div className="fa fa-pen-to-square"></div>
                                      <div className="">Edit</div>
                                    </Button>
                                  )}

                                  {editableUser && (
                                    <Button
                                      variant="success"
                                      className="d-flex justify-content-center align-items-center gap-2"
                                      onClick={handleSaveUser}
                                    >
                                      <div className="fa fa-save"></div>
                                      <div className="">Save</div>
                                    </Button>
                                  )}
                                </div>
                                <div className="mt-1">
                                  {editModeUser && (
                                    <Select
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: "100%",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          width: "100%",
                                        }),
                                      }}
                                      value={selectedRole}
                                      onChange={handleRoleChange}
                                      placeholder="Select Role"
                                      isMulti
                                      required
                                      options={options}
                                      formatOptionLabel={({ label }) => label}
                                      formatValueLabel={({ label }) =>
                                        `Selected: ${label}`
                                      }
                                      isClearable={true}
                                    />
                                  )}
                                </div>
                                <hr />
                              </>
                            )}
                            <Container>
                              <Row>
                                {data?.groups?.map((role, index) => (
                                  <>
                                    {/* <Col xs={2}></Col> */}
                                    <Col
                                      key={index}
                                      xs={6}
                                      className="d-flex justify-content-center mb-2 "
                                    >
                                      <Badge
                                        pill
                                        bg="primary"
                                        className="w-100 p-2"
                                      >
                                        {role}
                                      </Badge>
                                    </Col>
                                    {/* <Col xs={2}></Col> */}
                                  </>
                                ))}
                              </Row>
                            </Container>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </td>
                    <td>{data?.email ? data?.email : "N/A"}</td>
                    <td>
                      {data?.account_info?.forums?.[0]?.status
                        ? data?.account_info?.forums?.[0]?.status
                        : "N/A"}
                    </td>
                    {showEditButton && (
                      <td
                        className="text-primary text-nowrap"
                        onClick={() => nav("/users/details")}
                        style={{ cursor: "pointer" }}
                      >
                        View Details
                        <span className="bi bi-chevron-right"></span>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            )
          )}

          {isLoad && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
    </SweetLayout>
  );
};

export default UserTab;
