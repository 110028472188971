import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Col, Row, Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { api } from "../../API";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import Loader from "../loader";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import NavigationBackButton from "../navigateback/NavigationBackButton";
import SweetLayout from "../../Layout/SweetLayout";

const CreateAccount = () => {
  const { getIdTokenClaims, isLoading } = useAuth0();
  const [channels, setChannels] = useState([]);
  const nav = useNavigate();
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { setIsLoad, isLoad } = useContext(AppContext);
  const handleUnauthorized = useHandleUnauthorized();

  let endpoint = `${config.baseURL}/admin/v1/channel`;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    channel_ids: [],
  });

  const clearForm = () => {
    setFormData({
      name: "",
      email: "",
      channel_ids: [],
    });
  };

  const fetchChannels = async () => {
    setIsLoad(true);
    try {
      const getIdToken = await getIdTokenClaims();
      const idToken = getIdToken.__raw;
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      setChannels(response.data);
    } catch (error) {
      console.error("Error fetching channels:", error);
      handleUnauthorized(error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, [getIdTokenClaims, isLoading, handleUnauthorized]);

  useEffect(() => {
    setFilteredChannels(channels);
  }, [channels]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelect = (channelId, isChecked) => {
    setFormData((prevFormData) => {
      const channelIdsUpdated = isChecked
        ? [...prevFormData.channel_ids, channelId]
        : prevFormData.channel_ids.filter((id) => id !== channelId);

      return {
        ...prevFormData,
        channel_ids: channelIdsUpdated,
      };
    });
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      setFilteredChannels(channels);
    } else {
      setFilteredChannels(
        channels.filter(
          (channel) =>
            channel.name.toLowerCase().includes(searchTerm) ||
            channel.channel.toString().includes(searchTerm) // Convert to string before including
        )
      );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoad(true);
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;

      const response = await api.post("/admin/v1/account", formData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      setFormData({
        name: "",
        email: "",
        channel_ids: [],
      });
      toast.success("Account created successfully!");
      setTimeout(() => {
        nav(`/account/${response?.data?.account_id}`);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoad(false);
    }
  };
  useEffect(() => {}, [filteredChannels]);
  return (
    <SweetLayout>
    <div
      style={{
        // overflowX: "auto",
        // overflowY: "auto",
        position: "relative",
        // minHeight: "10px",
      }}
    >
      {isLoad && (
        <div
          style={{
            position: "absolute",
            // top: "150px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // height: "300px",
            // border: "1px solid red",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "110%",
            top: "-30px",
          }}
        >
          <Loader />
        </div>
      )}
      <NavigationBackButton />
      <div className="">
        <h1>Create Account</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mt-2">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formOwnerUserId">
              <Form.Label className="fw-bold">Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Dropdown
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
              disabled={isLoad || channels.length === 0}
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: "white",
                  color: "grey",
                  width: "100%",
                }}
                id="dropdown-basic"
                // disabled={isLoad || !channels.length}
                disabled={isLoad}
              >
                Select Channels
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ maxHeight: "300px", overflowY: "auto" }}>
                <Form.Control
                  autoFocus
                  className="mx-3 my-2 w-auto"
                  placeholder="Search channels..."
                  onChange={handleSearch}
                  value={searchTerm}
                />
                {filteredChannels.map((channel) => (
                  <Form.Check
                    style={{ paddingLeft: "20px" }}
                    type="checkbox"
                    id={`dropdown-check-${channel.ksc_id}`}
                    key={channel.channel}
                    label={`${channel.name}  (${channel.channel})`}
                    onChange={(e) =>
                      handleSelect(channel.channel, e.target.checked)
                    }
                    checked={formData.channel_ids.includes(channel.channel)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <ToastContainer />

        <div className="d-flex gap-2 align-items-center mt-3">
          <div>
            <Button variant="primary" type="submit">
              Create Account
            </Button>
          </div>
          <div>
            <Button variant="danger" onClick={clearForm}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
    </SweetLayout>
  );
};

export default CreateAccount;
