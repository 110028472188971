import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Context from "../src/AppContext/Context.jsx";

const root = document.getElementById("root");

createRoot(root).render(
  <React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>
);
