import React, {useState, useEffect, useContext} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { Row, Col, Container, ListGroup, Card } from "react-bootstrap";
import SweetLayout from "../../Layout/SweetLayout";
import axios from "axios";
import { AppContext } from "../../AppContext/Context";
import Select from "react-select";
import Papa from "papaparse";
import GroupedData from "./partials/GroupedData";
import { set } from "date-fns";


function Experiments() {
    const {
        setIsLoad,
        isLoad,
      } = useContext(AppContext);

    const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

    const [experiments, setExperiments] = useState([]);

    const [experimentsCSVFiles, setExperimentsCSVFiles] = useState([]);

    const [csvData, setCsvData] = useState({});

    const getExperimentsList = async () => {
        if(!isLoading){
            setIsLoad(true);
            let endpoint = `${config.baseURL}/admin/v1/comparisons`;
        try {
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;
            const response = await axios.get(endpoint, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            });
            const responseData = response.data;
              setExperiments(responseData);
              console.log(responseData);
          } catch (error) {
            console.error("API request error:", error.message);
          } finally {
            setIsLoad(false);
          }
        }
        };

        const getExperimentDetails = async (experiment) => {
            if(!isLoading){
                setIsLoad(true);
                setCsvData({});
                let endpoint = `${config.baseURL}/admin/v1/comparison?comparison_name=${experiment}`;
            try {
                const getidToken = await getIdTokenClaims();
                const idToken = getidToken.__raw;
                const response = await axios.get(endpoint, {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                  },
                });
                const responseData = response.data;
                setExperimentsCSVFiles(responseData);
                  console.log(responseData);
              } catch (error) {
                console.error("API request error:", error.message);
              } finally {
                setIsLoad(false);
              }
            }
            };



        useEffect(() => {
            if(isAuthenticated){
                getExperimentsList();
            }
          },[isLoading, isAuthenticated]);

          const handleChange = (selectedOption) => {
            console.log(selectedOption);
            getExperimentDetails(selectedOption.value);
            }


            useEffect(() => {
              const fetchCsvData = async () => {
                const resultData = {};
                const categories = Object.keys(experimentsCSVFiles);
            
                for (const category of categories) {
                  resultData[category] = [];
            
                  for (const url of experimentsCSVFiles[category]) {
                    try {
                      // Fetch the CSV file
                      const response = await fetch(url);
            
                      if (!response.ok) {
                        console.error(`Failed to fetch CSV from ${url}:`, response.statusText);
                        continue;
                      }
            
                      const csvText = await response.text();
            
                      // Dynamically determine the file name based on the URL
                      let parentKey;
                      if (url.includes('answer_scores')) {
                        parentKey = 'answer_scores';
                      } else if (url.includes('retrieval_scores')) {
                        parentKey = 'retrieval_scores';
                      } else {
                        parentKey = 'unknown'; // Fallback key if neither matches
                      }
            
                      // Parse the CSV data
                      Papa.parse(csvText, {
                        header: true, // Use first row as header
                        skipEmptyLines: true,
                        complete: (result) => {
                          // Ensure resultData[category] has the parentKey
                          if (!resultData[category][parentKey]) {
                            resultData[category][parentKey] = [];
                          }
            
                          // Append the parsed data to the appropriate parent key
                          resultData[category][parentKey].push(result.data);
            
                          // After each fetch and parse, update the state
                          setCsvData({ ...resultData });
                        },
                      });
                    } catch (error) {
                      console.error(`Failed to fetch or parse CSV from ${url}:`, error);
                    }
                  }
                }
              };
            
              fetchCsvData();
            }, [experimentsCSVFiles]);
            

            useEffect(() => {
              console.log(csvData);
            }
            ,[csvData]);

            const renderData = (data) => {
              if (typeof data === 'object' && !Array.isArray(data)) {
                // If data is an object, render it as a list of key-value pairs
                return (
                  <ul>
                    {Object.keys(data).map((key) => (
                      <li key={key}>
                        <strong>{key}:</strong> {renderData(data[key])}
                      </li>
                    ))}
                  </ul>
                );
              } else if (Array.isArray(data)) {
                // If data is an array, render each element in the array
                return (
                  <ul>
                    {data.map((item, index) => (
                      <li key={index}>{renderData(item)}</li>
                    ))}
                  </ul>
                );
              } else {
                // For primitive types like string, number, or boolean, render the value directly
                return <span>{String(data)}</span>;
              }
            };

            const keyGroups = [
              { startingKeyName: 'ept_answer_contains_', heading: 'EPT Answer Contains' },
              { startingKeyName: 'llm_ept_answer_grade_to_agent_an', heading: 'LLM ept answer grade' },
              { startingKeyName: 'llm_ept_answer_correct', heading: 'LLM ept answer correct' },
              { startingKeyName: 'percentage_agent_answer_links', heading: 'Percentage agent answer links' },
              { startingKeyName: 'llm_ept_answer_can_reproduce', heading: 'LLM ept answer can reproduce' },
              { startingKeyName: 'llm_ept_answer_doesnt_go_off_topic', heading: 'llm_ept_answer_doesnt_go_off_topic' },
              { startingKeyName: 'llm_ept_ai_has_no_harmful', heading: 'llm_ept_ai_has_no_harmful' },
              { startingKeyName: 'retrievals_cover_agent_answer_links', heading: 'retrievals_cover_agent_answer_links' },
              { startingKeyName: 'percentage_retrieval_links_in_ept_answer', heading: 'percentage_retrieval_links_in_ept_answer' },
              { startingKeyName: 'weighted_average_answer_score', heading: 'weighted_average_answer_score' },
              { startingKeyName: 'weighted_average_retrieval_score', heading: 'weighted_average_retrieval_score' },
              { startingKeyName: 'weighted_average_question', heading: 'weighted_average_question' },

            ];
            
            

    return (
        <SweetLayout>

          <div className="reporting-page experiments-page">
            <Row>
              <Col>
                <h1>Experiments</h1>
              </Col>
            </Row>
            <Row>
                <Col>

                    <Select
                    options={experiments.map((experiment) => {
                        return { value: experiment, label: experiment };
                    })}
                    onChange={handleChange}
                    />
                </Col>
            </Row>

            <Row>
            <div>
            <Container className="my-4">
            <Row>
      {Object.keys(csvData).map((category) => (
        <Col md={6} key={category} className="my-4">
          <h2 className="text-center mb-4">{category}</h2>
          <Row>
            {Object.keys(csvData[category]).map((dataType) => (
              <Col md={12} key={dataType} className="mb-4">
                <Card>
                  <Card.Header className="bg-primary text-white">
                    <h4 className="mb-0">{dataType.toUpperCase()}</h4>
                  </Card.Header>
                  <Card.Body>
                    {csvData[category][dataType].length > 0 ? (
                      <ListGroup variant="flush">
                        {csvData[category][dataType].map((dataArray, index) =>
                          dataArray.map((dataRow, rowIndex) => (
                            <GroupedData
                              key={rowIndex}
                              data={dataRow}
                              keyGroups={keyGroups}  // Pass the keyGroups to the component
                            />
                          ))
                        )}
                      </ListGroup>
                    ) : (
                      <p className="text-muted">No data available for {dataType}</p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      ))}
      </Row>
    </Container>




            </div>
            </Row>
          </div>
    </SweetLayout>
    );
}

export default Experiments;