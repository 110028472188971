import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { api } from "../../API";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Loader from "../loader";
import Select from "react-select";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import NavigationBackButton from "../navigateback/NavigationBackButton";
import SweetLayout from "../../Layout/SweetLayout";

const CreateChannel = () => {
  const { getIdTokenClaims, isLoading } = useAuth0();
  const { setIsLoad, isLoad } = useContext(AppContext);
  const [selectedForum, setSelectedForum] = useState([]);
  const nav = useNavigate();
  const [selectedType, setSelectedType] = useState([]);
  const [selectedKsc, setSelectedKsc] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [kscData, setKscData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [email, setEmail] = useState(false);
  const [kscOptions, setKscOptions] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const handleUnauthorized = useHandleUnauthorized();

  const [selectedGenerate, setSelectedGenerate] = useState(null);
  const [URLname, setURLname] = useState("URL");
  const [formData, setFormData] = useState({
    name: "",
    generate: "",
    ksc_id: "",
    owner_account_id: null,
    type: "",
    channel_details: {},
  });
  const clearForm = () => {
    setSelectedType([]);
    setSelectedKsc([]);
    setSelectedForum([]);
    setSelectedOwner([]);

    setEmail(false);

    setFormData({
      name: "",
      generate: "",
      ksc_id: "",
      url: "",
      owner_account_id: null,
      type: "",
      channel_details: {},
    });
  };

  const handleTypeChange = (selectedOptions) => {
    const selectedTypeValue = selectedOptions ? selectedOptions.value : "";

    let presetURL = "";
    if (selectedTypeValue === "ept-ai-app") {
      presetURL = "https://app.ept.ai";
    } else if (selectedTypeValue === "email") {
      presetURL = "mailto:question@ept.ai";
    } else if (selectedTypeValue === "chat") {
      presetURL = "www.ept.ai";
    } else if (selectedTypeValue === "websocket_api") {
      presetURL = "ws://ws.ept.ai";
    }

    setFormData({
      ...formData,
      type: selectedTypeValue,
      url: presetURL,
    });
    setSelectedType(selectedOptions);

    selectedTypeValue === "email" ? setEmail(true) : setEmail(false);
    if (selectedTypeValue === "zendesk_knowledge_base") {
      setURLname("Zendesk URL");
    } else if (selectedTypeValue === "slack") {
      setURLname("Slack URL");
    } else if (selectedTypeValue === "hubspot") {
      setURLname("Hubspot URL");
    } else if (selectedTypeValue === "chat") {
      setURLname("Chat URL");
    } else {
      setURLname("URL");
    }
  };

  const handleKSCSelect = (selectedOption) => {
    if (selectedOption) {
      handleChange({ target: { name: "ksc_id", value: selectedOption.value } });
    }
    setSelectedKsc(selectedOption);
  };
  const handleOwnerSelect = (selectedOption) => {
    if (selectedOption) {
      handleChange({
        target: { name: "owner_account_id", value: selectedOption.value },
      });
    }
    setSelectedOwner(selectedOption);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "generate") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        channel_details: {
          ...prevFormData.channel_details,
          generate: value,
        },
      }));
    }
    let parsedValue = value;

    if (
      (name === "owner_account_id" || name === "ksc_id") &&
      value !== null &&
      value !== ""
    ) {
      parsedValue = parseInt(value);
      if (isNaN(parsedValue)) {
        parsedValue = "";
      }
    }
    if (
      name === "zendeskURL" ||
      name === "slackURL" ||
      name === "hubspotURL" ||
      name === "conversation_apiURL" ||
      name === "eptAiAppURL" ||
      name === "email_domain" ||
      name === "chatURL" ||
      name === "websocket"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        channel_details: {
          ...prevFormData.channel_details,
          [name]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: parsedValue,
      });
    }
  };
  const handleGenerateChange = (selectedOption) => {
    setSelectedGenerate(selectedOption);

    setFormData((prevFormData) => ({
      ...prevFormData,
      generate: selectedOption.value,
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setLoadingCount((prevCount) => prevCount + 1);
        const endpoint = `${config.baseURL}/admin/v1/account`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await api.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });

          setOwnerData(response.data);
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setLoadingCount((prevCount) => prevCount - 1);
        }
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims, handleUnauthorized]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCount((prevCount) => prevCount + 1);
      const endpoint = `${config.baseURL}/admin/v1/ksc`;

      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await api.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setKscData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setLoadingCount((prevCount) => prevCount - 1);
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims]);

  const endpoint = `${config.baseURL}/admin/v1/channel`;

  const [channels, setChannels] = useState([]);

  const fetchChannels = async () => {
    setLoadingCount((prevCount) => prevCount + 1);
    try {
      var getIdToken = await getIdTokenClaims();
      const idToken = getIdToken.__raw;
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setChannels(response?.data);
    } catch (error) {
      console.error("Error fetching channels:", error);
    } finally {
      setLoadingCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, [getIdTokenClaims, isLoading]);

  useEffect(() => {
    setIsLoad(loadingCount > 0);
  }, [loadingCount]);

  useEffect(() => {
    setKscOptions(
      kscData.map((channel) => ({
        value: channel.ksc_id,
        label: `${channel.name} (${channel.ksc_id})`,
      }))
    );
  }, [kscData]);

  useEffect(() => {
    setOwnerOptions(
      ownerData.map((channel) => ({
        value: channel.account_id,
        label: `${channel.name} (${channel.account_id})`,
      }))
    );
  }, [ownerData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;

      const response = await api.post("/admin/v1/channel", formData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      setFormData({
        name: "",
        url: "",
        type: "",
        owner_account_id: null,
        generate: "",
        ksc_id: "",
        channel_details: {},
      });
      toast.success("Channel created successfully!");
      setTimeout(() => {
        nav(`/channel/${response?.data?.channel}`);
      }, 2000);
      setSelectedType([]);
      setSelectedKsc([]);
      setSelectedOwner([]);
      setSelectedForum([]);

      setEmail(false);
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoad(false);
    }
  };
  const handleChannelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      channel_details: {
        ...prevFormData.channel_details,
        [name]: value,
      },
    }));
  };
  return (
    <SweetLayout>
    <div>
      {isLoad && (
        <div
          style={{
            position: "absolute",
            left: "60%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "100%",
            top: "60px",
            // zIndex: 100,
            // height: "50%",
          }}
        >
          <Loader />
        </div>
      )}
      <ToastContainer />
      <NavigationBackButton />
      <div className="">
        <h1>Create Channel</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mt-2">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label className="fw-bold mt-2">Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formURL">
              <Form.Label className="fw-bold mt-1">{URLname}:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter URL"
                name="url"
                value={formData.url}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formType">
              <Form.Label className="fw-bold mt-2">Type:</Form.Label>
              <Select
                id="type"
                onChange={handleTypeChange}
                value={selectedType}
                options={[
                  { value: "community_forum", label: "Community forum" },
                  {
                    value: "zendesk_knowledge_base",
                    label: "Zendesk",
                  },
                  { value: "slack", label: "Slack" },
                  { value: "hubspot", label: "Hubspot" },

                  { value: "email", label: "Email" },
                  { value: "chat", label: "Chat" },
                  { value: "ept-ai-app", label: "Ept-Ai-App" },
                  { value: "websocket", label: "Websocket" },
                ]}
                isSearchable
                placeholder="Select Type"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formOwnerAccountId">
              <Form.Label className="fw-bold mt-2">
                Owner Account ID:
              </Form.Label>

              <Select
                id="channels"
                value={selectedOwner}
                onChange={handleOwnerSelect}
                options={ownerOptions}
                placeholder="Select Owner Account ID"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formGenerate">
              <Form.Label className="fw-bold mt-2">Generate:</Form.Label>
              <Select
                id="generate"
                onChange={handleGenerateChange}
                value={selectedGenerate}
                options={[
                  { value: "all", label: "All" },
                  { value: "never", label: "Never" },
                  { value: "unresponded", label: "Unresponded" },
                  { value: "unsolved", label: "Unsolved" },
                ]}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formKscId">
              <Form.Label className="fw-bold mt-2">
                Knowledge Source Configuration (KSC ID)
              </Form.Label>

              <Select
                id="channels"
                value={selectedKsc}
                onChange={handleKSCSelect}
                options={kscOptions}
                placeholder="Select Ksc"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formChannelDetails">
              <Form.Label className="fw-bold mt-2">Company Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                name="company_name"
                value={formData.channel_details.company_name}
                onChange={handleChannelChange}
                required
              />
            </Form.Group>
          </Col>

          {email && (
            <Col md={6}>
              <Form.Group controlId="formEmailUrl">
                <Form.Label className="fw-bold mt-2">Email Domain:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email Domain"
                  name="email_domain"
                  value={formData.emailURL}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          )}

          <Col md={6}></Col>
        </Row>
        <div className="d-flex gap-2 align-items-center mt-3">
          <div>
            <Button variant="primary" type="submit">
              Create Channel
            </Button>
          </div>
          <div>
            <Button variant="danger" onClick={clearForm}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
    </SweetLayout>
  );
};

export default CreateChannel;
