import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';

export const AppContext = createContext();


export default function Context({ children }) {
  const {
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const [isOpen, setIsOpen] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [channelData, setChannelData] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Function to check and renew token
  const checkAndRenewToken = async () => {
    if (!isLoading && !isAuthenticated) {
      // User is not authenticated, redirect to login
      localStorage.setItem('redirectPath', window.location.pathname);
      loginWithRedirect();
      return;
    }
    if (!isLoading) {
      console.log("Checking and renewing token");
      try {
          const checkToken = await getIdTokenClaims();
          // console.log(checkToken);
          if (checkToken) {
            const currentTime = Date.now() / 1000; // Current time in seconds
    
            // Check if the token has expired
            if (checkToken.exp < currentTime) {
              console.log("ID Token has expired");
              // Handle the expiration scenario (e.g., log out, renew token)
              logout({ logoutParams: { returnTo: window.location.origin } });
            } else {
              console.log("ID Token is still valid");
            }
          }
        
      } catch (error) {
        loginWithRedirect();
        console.error("Error renewing token: ", error);
        // Log the user out if token renewal fails
        
      }
    }
  };


  // Set up an interval for the token check
  useEffect(() => {
    checkAndRenewToken(); // Check immediately
    
    const intervalId = setInterval(checkAndRenewToken, 15 * 60 * 1000); // Check every 15 minutes
    // const intervalId = setInterval(checkAndRenewToken, 15 * 1000); // Check every 15 seconds


    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [isLoading, getIdTokenClaims, logout, getAccessTokenSilently]);

  return (
    <AppContext.Provider
      value={{
        isOpen,
        setIsOpen,
        isLoad,
        setIsLoad,
        data,
        setData,
        filter,
        setFilter,
        channelData,
        setChannelData,
        selectedChannel,
        setSelectedChannel,
        selectedType,
        setSelectedType,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
