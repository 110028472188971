import React from "react";
import { Table, Modal, Button } from "react-bootstrap";

const NestedObjectModal = ({ visible, onClose, data, title }) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return "No Date Provided";
    const date = new Date(timestamp * 1000); // Multiply by 1000 if the timestamp is in seconds
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // This ensures AM/PM format
    });
  };

  const renderValue = (key, value) => {
    if (key === "last_updated_date") {
      return formatDate(value);
    }
    if (
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return "N/A";
    } else if (value === true || value === false) {
      return value.toString();
    } else if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{renderValue(null, item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === "object") {
      return (
        <ul>
          {Object.entries(value).map(([nestedKey, nestedValue]) => (
            <li key={nestedKey}>
              <strong>{nestedKey}:</strong>{" "}
              {renderValue(nestedKey, nestedValue)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === "string" && value.includes(",")) {
      const parts = value.split(",");
      return (
        <ul>
          {parts.map((part, index) => (
            <li key={index}>{part}</li>
          ))}
        </ul>
      );
    } else {
      return value;
    }
  };

  return (
    <>
      <Modal show={visible} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && Object.keys(data).length > 0 ? (
            <Table responsive striped bordered hover className="x-auto">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <tr>
                      <td>{key}</td>
                      <td>{renderValue(key, value)}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
              style={{ minHeight: "300px" }}>
              No data found
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NestedObjectModal;
