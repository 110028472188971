import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Table, Button, Container, Form } from "react-bootstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import { config } from "../../config";
import NestedObjectModal from "../modals/NestedObjectModal";
import { AppContext } from "../../AppContext/Context";
import debounce from "lodash/debounce";
import Loader from "../loader";
import CustomPagination from "../../utils/pagination/CustomPagination";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import NoDataFound from "../errormessage/NoDataFound";
import FetchDataMessage from "../fetchdatamessage/FetchDataMessage";
import SweetLayout from "../../Layout/SweetLayout";

const Channel = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalTitle, setModalTitle] = useState("Nested Object Details");
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const nav = useNavigate();
  const {
    setIsLoad,
    isLoad,
    filter,
    setFilter,
    selectedType,
    setSelectedType,
  } = useContext(AppContext);
  const [data, setData] = useState([]);
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const handleUnauthorized = useHandleUnauthorized();
  const [filterError, setFilterError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("currentPage")) || 1
  );
  const recordsPerPage = 20;

  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);
      if (data.length === 0) {
        setCurrentPage(1);
      }
      let endpoint = `${config.baseURL}/admin/v1/channel_list`;

      if (filter) {
        const numericFilter = parseInt(filter);
        if (!isNaN(numericFilter)) {
          endpoint += `?query=${encodeURIComponent(filter)}`;
        } else {
          endpoint += `?query=${encodeURIComponent(filter)}`;
        }
      }

      if (selectedType) {
        let adjustedType = selectedType;
        if (selectedType === "ept AI app") {
          adjustedType = "ept-ai-app";
        }
        if (filter) {
          endpoint += `&type=${adjustedType}`;
        } else {
          endpoint += `?type=${adjustedType}`;
        }
      }

      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        const responseData = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setData(responseData);
        setFilterError(false);
      } catch (error) {
        if (error?.response?.status === 500) {
          setFilterError(true);
        } else {
          setFilterError(false);
        }
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    };

    const debouncedFetchData = debounce(fetchData, 2000);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [
    isLoading,
    filter,
    selectedType,
    getIdTokenClaims,
    setIsLoad,
    handleUnauthorized,
  ]);

  useEffect(() => {
    // Reset data and pagination when filter is cleared
    if (filter === "") {
      setData([]);
      setCurrentPage(1);
    }
  }, [filter]);

  const handleNestedObjectClick = async (record, key) => {
    const nestedData = record[key];
    setModalData(nestedData);
    setModalTitle(`${key}`);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const types = [
    "chat",
    "email",
    "community_forum",
    "hubspot",
    "zendesk",
    "slack",
    "ept AI app",
  ];

  const columns = [
    {
      title: "Channel ID",
      dataIndex: "channel",
      key: "channel",
      render: (text, record) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => nav(`/channel/${record.channel}`)}
        >
          <a
            href={`/channel/${record.channel}`}
            onClick={(e) => e.preventDefault()}
            style={{ textDecoration: "none" }}
          >
            <div className="p-1 text-center">{text}</div>
          </a>
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Ksc",
      dataIndex: "ksc",
      key: "ksc",
      render: (text, record) => (
        <Link to={`/ksc-details/${record.ksc_id}`}>{record.ksc_id}</Link>
      ),
    },
    {
      title: "Generate",
      dataIndex: "generate",
      key: "generate",
    },
    {
      title: "Confidential",
      dataIndex: "confidential",
      key: "confidential",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text
          ? "true"
          : "false",
    },
    {
      title: "Forum Type",
      dataIndex: "forum_type",
      key: "forum_type",
    },
    {
      title: "Kss",
      dataIndex: "kss",
      key: "kss",
      render: (text, record) => {
        const kssArray = Array.isArray(record.kss) ? record.kss : [record.kss];

        return (
          <>
            {kssArray.map((id, index) => (
              <React.Fragment key={index}>
                <Link to={`/ks/${id}`}>{id}</Link>
                {index !== kssArray.length - 1 && ","}
                {!record.kss && "null"}
              </React.Fragment>
            ))}
          </>
        );
      },
    },
    {
      title: "Owner Account ID",
      dataIndex: "owner_account_id",
      key: "owner_account_id",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text !== null
          ? text
          : "N/A",
    },
    {
      title: "Channel Details",
      dataIndex: "channel_details",
      key: "channel_details",
      render: (text, record) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleNestedObjectClick(record, "channel_details")}
        >
          View Details
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const isValidDate = !isNaN(Date.parse(created_at));
        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(created_at);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        const isValidDate = !isNaN(Date.parse(updated_at));
        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(updated_at);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
  ];

  const totalPages = Math.ceil(data.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    sessionStorage.setItem("currentPage", pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return data.slice(startIndex, startIndex + recordsPerPage);
  };

  return (
    <SweetLayout>
      <Container fluid>
        {showErrorMessage ? (
          <div
            className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
            style={{ minHeight: "300px" }}
          >
            You are not authorized to use this app. Please contact your ept AI
            administrator if you believe you should have access.
          </div>
        ) : (
          <div>
            <Row>
              <Col>
                <Row className="align-items-center">
                  <Col sm="12" md="12" lg="9">
                    <span className="d-flex flex-column flex-sm-row gap-2 mt-1">
                      <span className="d-flex gap-2">
                        <h1>Channels</h1>
                        <Form.Control
                          size="sm"
                          type="search"
                          style={{ maxWidth: "200px" }}
                          defaultValue={filter}
                          placeholder="Search with query string"
                          value={filter || ""}
                          onChange={(e) => {
                            setIsLoad(true);
                            const input = e.target.value;
                            setFilter(input);
                          }}
                        />
                      </span>
                      <Form.Select
                        aria-label="Select type"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        style={{ maxWidth: "200px" }}
                      >
                        <option value="">Search with type</option>
                        {types.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </span>
                  </Col>
                  {showEditButton && (
                    <Col sm="12" md="12" lg="3">
                      <div className="d-flex justify-content-lg-end justify-content-md-start mt-1">
                        <Button
                          variant="dark"
                          onClick={() => nav("/create-channel")}
                        >
                          Create New Channel
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>

                <div
                  style={{
                    overflowX: "auto",
                    position: "relative",
                  }}
                >
                  {isLoad && (
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        width: "100%",
                      }}
                    >
                      <Loader />
                    </div>
                  )}

                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="x-auto mt-2"
                  >
                    <thead>
                      <tr className="x-auto">
                        {columns.map((column) => (
                          <th key={column.key}>{column.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getPaginatedData().map((record, rowIndex) => (
                        <tr key={rowIndex}>
                          {columns.map((column) => (
                            <td key={column.key}>
                              {column.render
                                ? column.render(
                                    record[column.dataIndex],
                                    record
                                  )
                                : record[column.dataIndex]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {(!data || data.length === 0) && filter === "" && (
                    <FetchDataMessage style={{ minHeight: "300px" }} />
                  )}
                  {filterError && (
                    <NoDataFound style={{ minHeight: "300px" }} />
                  )}

                  {!isLoad && data.length === 0 && filter && selectedType && (
                    <NoDataFound style={{ minHeight: "300px" }} />
                  )}

                  {!isLoad && filterError && (
                    <NoDataFound style={{ minHeight: "300px" }} />
                  )}

                  {!filterError && data.length > 0 && (
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>

      <NestedObjectModal
        title={modalTitle}
        visible={modalVisible}
        data={modalData}
        onClose={closeModal}
      />
    </SweetLayout>
  );
};

export default Channel;
