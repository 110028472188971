import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Card, Col, Row, Container, Accordion } from "react-bootstrap";
import { api } from "../../API";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import NestedObjectModal from "../modals/NestedObjectModal";
// import Accordions from "../accordion/Accordion";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import { AppContext } from "../../AppContext/Context";
import Loader from "../loader";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import SweetLayout from "../../Layout/SweetLayout";

const KscDetailedTable = () => {
  const [owner, setOwner] = useState({});
  // const [modalVisible, setModalVisible] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState([]);
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [ksData, setKsData] = useState([]);
  const { id } = useParams();
  const [isEditKsDone, setIsEditKsDone] = useState(false);
  const [newConfidential, setNewConfidential] = useState(owner.confidential);
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const [editModeKs, setEditModeKs] = useState(false);
  const [editableKs, setEditableKs] = useState(false);
  const [selectedKs, setSelectedKs] = useState(null);
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const { setIsLoad, isLoad } = useContext(AppContext);
  const isActive = data?.status;
  const handleUnauthorized = useHandleUnauthorized();

  const [editableFields, setEditableFields] = useState({
    name: false,
    url: false,
    company_name: false,
    confidential: false,
    type: false,
    owner_account_id: false,
    source_details: false,
    description: false,
  });

  const [formData, setFormData] = useState({
    // name: data[0] ? data[0].name : "",
    // description: data[0] ? data[0].description : "",
    // confidential: data[0] ? data[0].confidential : "",
    // type: data[0] ? data[0].type : "",
    owner_account_id: owner?.owner_account_id,
    // source_details: data[0]
    // ? data[0]?.source_details
    // : {
    // url: "",
    // block_size: 2000,
    // url_filter: [],
    // blocked_urls: [],
    // pinecone_host: "",
    // embedding_model: "",
    // completions_model: "",
    // context_table_name: "",
    // pinecone_namespace: "",
    // pinecone_api_key_id: "",
    // },
  });

  const navigateToKsDetailPage = (id) => {
    nav(`/ks/${id}`);
  };

  useEffect(() => {
    if (owner) {
      setNewConfidential(owner.confidential);
    }
  }, [owner]);

  const renderId = (id) => (
    <span
      onClick={() => navigateToKsDetailPage(id)}
      style={{ color: "blue", cursor: "pointer" }}
    >
      <a
        href={`/ks/${id}`}
        onClick={(e) => e.preventDefault()}
        style={{ textDecoration: "none" }}
      >
        <div className="">{id}</div>
      </a>
    </span>
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoad(true);
        let endpoint = `${config.baseURL}/admin/v1/ks`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          const responseData = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setKsData(responseData);
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims, handleUnauthorized, setIsLoad]);

  const options = ksData.map((data) => ({
    label: `${data.name}(${data.ks_id})`,
    value: data.ks_id,
  }));

  useEffect(() => {
    if (data[0]) {
      setFormData({
        // name: data[0].name,
        // description: data[0].description,
        // confidential: data[0].confidential,
        // type: data[0].type,
        owner_account_id: owner?.owner_account_id,
        // name: owner?.name,
        // description: owner?.description,
        // source_details: owner?.source_details,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoad(true);
      const fetchData = async () => {
        const endpoint = `${config.baseURL}/admin/v1/ksc/${id}`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });

          setData(response.data.kss);
          setOwner(response.data);
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      };
      fetchData();
    }
  }, [isLoading, id, getIdTokenClaims, isEditKsDone, handleUnauthorized]);

  const renderAccordion = (key, title, record) => (
    <Accordion className="mt-4 mb-4" key={key}>
      <Card>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong>{title}</strong>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="d-flex flex-column gap-2">
              <Card.Body>
                {record[key] ? (
                  Object.entries(record[key]).map(
                    ([nestedKey, nestedValue], index) => (
                      <li key={index}>
                        <div>
                          <strong>{nestedKey}</strong>:{" "}
                          {Array.isArray(nestedValue) ? (
                            <Accordion className="mt-2 mb-2">
                              <Accordion.Item eventKey={index.toString()}>
                                <Accordion.Header>{nestedKey}</Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {nestedValue.map((item, idx) => (
                                      <li key={idx}>{item}</li>
                                    ))}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          ) : (
                            renderNestedValue(nestedValue, nestedKey)
                          )}
                        </div>
                      </li>
                    )
                  )
                ) : (
                  <strong>No Data</strong>
                )}
              </Card.Body>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  );
  const formatDate = (timestamp) => {
    if (!timestamp) return "No Date Provided";
    const date = new Date(timestamp * 1000);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };
  const renderNestedValue = (value, key) => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return (
        <ul>
          {Object.entries(value).map(([nestedKey, nestedValue], index) => (
            <li key={index}>
              <strong>{nestedKey}</strong>: {nestedValue}
            </li>
          ))}
        </ul>
      );
    } else if (Array.isArray(value)) {
      return null;
    } else {
      return key === "last_updated_date" ? formatDate(value) : value;
    }
  };

  const handleChange = (e, fieldName, nestedField = null, index = null) => {
    const { value, checked } = e.target;

    if (nestedField && index !== null) {
      setFormData((prevState) => ({
        ...prevState,
        source_details: {
          ...prevState.source_details,
          [nestedField]: prevState.source_details[nestedField].map((item, i) =>
            i === index ? value : item
          ),
        },
      }));
    } else if (nestedField) {
      setFormData((prevState) => ({
        ...prevState,
        source_details: {
          ...prevState.source_details,
          [nestedField]: value,
        },
      }));
    } else if (fieldName === "confidential") {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  // const handleNestedObjectClick = (record, key) => {
  // const nestedData = record[key];
  // setSelectedRecord({ data: nestedData, title: `${key} Details` });
  // setModalVisible(true);
  // };
  // const closeModal = () => {
  // setModalVisible(false);
  // setSelectedRecord(null);
  // };

  const handleEditClick = (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: true,
    }));
  };

  const handleSaveClick = async (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
    setIsLoad(true);

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = formData;
      const response = await api.put(`/admin/v1/ksc/${id} `, payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      setIsLoad(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoad(false);
    }
  };
  const column = [
    {
      title: "Ks-ID",
    },
    { title: "Name" },
    { title: "Type" },
    ...(showEditButton ? [{ title: "Actions" }] : []),
  ];

  let KSpayload = data.map((item) => item.ks_id);

  if (selectedKs && selectedKs.value) {
    KSpayload = [...KSpayload, selectedKs.value];
  }
  const handleRemoveKs = async (ks_id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this item?"
    );
    if (isConfirmed) {
      KSpayload = KSpayload.filter((id) => id !== ks_id);

      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await api.put(
          `/admin/v1/ksc/${id}`,
          { ks_ids: KSpayload },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setIsEditKsDone(!isEditKsDone);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoad(false);
      }
    }
  };

  const handleEditKs = () => {
    setEditModeKs(true);
    setEditableKs(true);
  };
  const hasSameIDs = (arr) => {
    const idSet = new Set(arr);
    return arr.length !== idSet.size;
  };
  const handleSaveKs = async () => {
    setEditModeKs(false);
    setEditableKs(false);

    if (!selectedKs) {
      return;
    }

    const hasDuplicates = hasSameIDs(KSpayload);

    // If there are duplicate IDs, handle accordingly
    if (hasDuplicates) {
      alert("This KS is already added.");
      return;
    }

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;

      const response = await api.put(
        `/admin/v1/ksc/${id}`,
        { ks_ids: KSpayload },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setIsEditKsDone(!isEditKsDone);
      setIsLoad(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoad(false);
    } finally {
      setSelectedKs(null);
    }
  };

  const handleKsChange = (selectedOption) => {
    setSelectedKs(selectedOption);
  };
  const handleNewConfidential = (e) => {
    setNewConfidential(e.target.checked, newConfidential);
  };
  return (
    <SweetLayout>
      <Container fluid>
        {isLoad && (
          <div
            style={{
              position: "absolute",
              top: "150px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // height: "300px",
              // border: "1px solid red",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        )}
        <Row>
          <Col>
            <div className="">
              <Row>
                <Col md="6" sm="12">
                  <div>
                    <div
                      onClick={() => nav(-1)}
                      className="fe-5 fw-bold"
                      style={{ cursor: "pointer" }}
                    >
                      <span className="bi bi-chevron-left"></span> Back
                    </div>
                    <h1 className="mb-0">KSC</h1>
                    <p>Knowledge Source Configuration</p>
                  </div>
                </Col>
                <Col md="6" sm="12">
                  <div className="d-flex justify-content-md-end justify-content-sm-start ">
                    <h2 className="">KSC ID: {owner.ksc_id}</h2>
                  </div>
                </Col>
              </Row>

              <div>
                <Row className="align-items-center mb-2">
                  {/* <Col sm="12" md="12" lg="3" className="mt-2">
                    <div className="">
                      <span className="fw-bold">Status:</span>
                      <span>{data?.status} ? </span>
                    </div>
                  </Col> */}

                  <Col sm="12" md="12" lg="12" className="mt-2">
                  {/* <Col sm="12" md="12" lg="9" className="mt-2"> */}
                    <Row>
                      <Col sm="6" md="6" lg="4">
                        <span>
                          <div className="fw-bold">Name:</div>
                          <input
                            type="text"
                            readOnly={!editableFields.name}
                            defaultValue={owner.name}
                            className="white-background-input"
                            style={{
                              background: "transparent",
                              border: editableFields.name
                                ? "1px solid #ccc"
                                : "none",
                              outline: "none",
                            }}
                            onChange={(e) =>
                              editableFields.name && handleChange(e, "name")
                            }
                          />
                          {editableFields.name ? (
                            <React.Fragment>
                              <i
                                className="fa fa-save"
                                onClick={() => handleSaveClick("name")}
                                style={{
                                  cursor: "pointer",
                                  // marginRight: "15px",
                                  marginLeft: "5px",
                                }}
                              ></i>
                            </React.Fragment>
                          ) : (
                            showEditButton && (
                              <i
                                className="fa fa-pen-to-square"
                                onClick={() => handleEditClick("name")}
                                style={{
                                  cursor: "pointer",
                                  // marginRight: "15px",
                                  marginLeft: "5px",
                                }}
                              ></i>
                            )
                          )}
                        </span>
                      </Col>
                      <Col sm="6" md="6" lg="4">
                        <div>
                          <div className="fw-bold">Description:</div>
                          <input
                            type="text"
                            readOnly={!editableFields.description}
                            defaultValue={owner.description}
                            className="white-background-input"
                            style={{
                              border: editableFields.description
                                ? "1px solid #ccc"
                                : "none",
                              outline: "none",
                              background: "transparent",

                            }}
                            onChange={(e) =>
                              editableFields.description &&
                              handleChange(e, "description")
                            }
                          />
                          {editableFields.description ? (
                            <React.Fragment>
                              <i
                                className="fa fa-save"
                                onClick={() => handleSaveClick("description")}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                              ></i>
                            </React.Fragment>
                          ) : (
                            showEditButton && (
                              <i
                                className="fa fa-pen-to-square"
                                onClick={() => handleEditClick("description")}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                              ></i>
                            )
                          )}
                        </div>
                      </Col>
                      <Col sm="6" md="6" lg="2">
                        <span>
                          <div className="fw-bold">Confidential:</div>
                          <div className="d-flex justify-content-between align-items-center">
                            <input
                              type="checkbox"
                              readOnly={!editableFields.confidential}
                              checked={newConfidential}
                              className="white-background-input"
                              style={{
                                border: editableFields.confidential
                                  ? "1px solid #ccc"
                                  : "none",
                                outline: "none",
                              }}
                              onChange={(e) => {
                                if (editableFields.confidential) {
                                  handleChange(e, "confidential");
                                  handleNewConfidential(e, "confidential");
                                }
                              }}
                            />
                            {editableFields.confidential ? (
                              <React.Fragment>
                                <i
                                  className="fa fa-save"
                                  onClick={() =>
                                    handleSaveClick("confidential")
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "15px",
                                  }}
                                ></i>
                              </React.Fragment>
                            ) : (
                              showEditButton && (
                                <i
                                  className="fa fa-pen-to-square"
                                  onClick={() =>
                                    handleEditClick("confidential")
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "15px",
                                  }}
                                ></i>
                              )
                            )}
                          </div>
                        </span>
                      </Col>
                      <Col sm="6" md="6" lg="2">
                        <span>
                          <div className="fw-bold">Owner Account ID:</div>
                          <div className="d-flex justify-content-between align-items-center">
                            {editableFields?.owner_account_id ? (
                              <input
                                type="number"
                                defaultValue={owner.owner_account_id}
                                className="white-background-input w-100"
                                style={{
                                  border: editableFields.owner_account_id
                                    ? "1px solid #ccc"
                                    : "none",
                                  outline: "none",
                                }}
                                onChange={(e) =>
                                  handleChange(e, "owner_account_id")
                                }
                              />
                            ) : (
                              owner.owner_account_id
                            )}
                            {showEditButton &&
                              !editableFields.owner_account_id && (
                                <i
                                  className="fa fa-pen-to-square"
                                  onClick={() =>
                                    handleEditClick("owner_account_id")
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "15px",
                                  }}
                                ></i>
                              )}
                            {editableFields.owner_account_id && (
                              <i
                                className="fa fa-save"
                                onClick={() =>
                                  handleSaveClick("owner_account_id")
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "15px",
                                }}
                              ></i>
                            )}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Accordion className="mb-5">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <strong>KSs</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        style={{
                          position: "relative",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        {editModeKs && (
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "20rem",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "20rem",
                              }),
                            }}
                            value={selectedKs}
                            onChange={handleKsChange}
                            // placeholder="Select Role"
                            // isMulti
                            // required
                            options={options}
                            // formatOptionLabel={({ label }) => label}
                            // formatValueLabel={({ label }) =>
                            // `Selected: ${label}`
                            // }
                            isClearable={true}
                          />
                        )}
                        {showEditButton && !editableKs && (
                          <i
                            className="fa fa-plus-square-o"
                            onClick={handleEditKs}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              position: "absolute",
                              right: "0",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          ></i>
                        )}
                        {editableKs && (
                          <i
                            className="fa fa-save"
                            onClick={handleSaveKs}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          ></i>
                        )}
                      </div>
                      <Table
                        responsive
                        striped
                        bordered
                        hover
                        className="x-auto hide-table"
                        style={{ minHeight: "106px" }}
                      >
                        <thead>
                          <tr>
                            {column.map((column, index) => (
                              <th key={index}>{column.title}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {renderId(item.ks_id ? item.ks_id : "Null")}
                              </td>
                              <td>{item.name ? item.name : "Null"}</td>
                              <td>{item.type ? item.type : "Null"}</td>
                              {showEditButton && (
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleRemoveKs(item.ks_id)}
                                  >
                                    Remove
                                  </Button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {data?.map((item, index) => (
                  <>
                    <div
                      className="p-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "white",
                        borderRadius: "5px",
                      }}
                    >
                      <h4 style={{ marginTop: 0 }}>
                        <strong>Data</strong>
                      </h4>
                      <Row>
                        <Col sm="6" md="6" lg="3">
                          <div>
                            <div className="fw-bold">Name:</div>
                            {item.name}
                          </div>
                        </Col>
                        <Col sm="6" md="6" lg="3">
                          <div>
                            <div className="fw-bold">Description:</div>
                            {item.description}
                          </div>
                        </Col>
                        <Col sm="6" md="6" lg="3">
                          <div>
                            <div className="fw-bold">Type:</div>
                            {item.type}
                          </div>
                        </Col>
                        <Col sm="6" md="6" lg="3">
                          <div>
                            <div className="fw-bold">Ks ID:</div>
                            {renderId(item.ks_id)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="">
                      {renderAccordion(
                        "source_details",
                        "Source Details",
                        item
                      )}
                      {renderAccordion("stats", "Stats", item)}
                      {renderAccordion("ept_config", "EPT Config", item)}
                      {renderAccordion("versions", "Versions", item)}
                    </div>
                    {/* <Accordions data={data}/> */}
                  </>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      

      {/* <NestedObjectModal
visible={modalVisible}
onClose={closeModal}
data={selectedRecord?.data}
title={selectedRecord?.title}
/> */}
</SweetLayout>
  );
};
export default KscDetailedTable;
