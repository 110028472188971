
// const localMode = "prod";
const localMode = "dev";

const API_URLS = {
    "eptai-admin.dev-ept-ai.com": "https://api.dev-ept-ai.com",
    "dev-eptaiadmin.ept.ai": "https://api.ept.ai"
};

const AUTH_DOMAINS = {
    "eptai-admin.dev-ept-ai.com": "eptai-dev.us.auth0.com",
    "dev-eptaiadmin.ept.ai": "login.ept.ai"
};

const AUTH_CLIENT_IDS = {
    "eptai-admin.dev-ept-ai.com": "kXTEAzkttuuNt6xoqHaoam2AA5WmZFoz",
    "dev-eptaiadmin.ept.ai": "RXyxcWkLx7y2Xcw92lr3yrXKUINXj0o4"
};

function getEnvironmentConfig(key) {
    const location = window.location.hostname;
    const devConfig = {
        apiPath: API_URLS["eptai-admin.dev-ept-ai.com"],
        authDomain: AUTH_DOMAINS["eptai-admin.dev-ept-ai.com"],
        authClientId: AUTH_CLIENT_IDS["eptai-admin.dev-ept-ai.com"]
    };
    const prodConfig = {
        apiPath: API_URLS["dev-eptaiadmin.ept.ai"],
        authDomain: AUTH_DOMAINS["dev-eptaiadmin.ept.ai"],
        authClientId: AUTH_CLIENT_IDS["dev-eptaiadmin.ept.ai"]
    };
    const defaultConfig = {
        apiPath: "https://api.ept.ai",
        authDomain: "login.ept.ai",
        authClientId: "RXyxcWkLx7y2Xcw92lr3yrXKUINXj0o4"
    };

    switch(location) {
        case "eptai-admin.dev-ept-ai.com":
            return devConfig[key];
        case "dev-eptaiadmin.ept.ai":
            return prodConfig[key];
        case "localhost":
            return localMode === "dev" ? devConfig[key] : prodConfig[key];
        default:
            return defaultConfig[key];
    }
}

export function apiPath() {
    return getEnvironmentConfig('apiPath');
}

export function auth0Domain() {
    return getEnvironmentConfig('authDomain');
}

export function auth0ClientId() {
    return getEnvironmentConfig('authClientId');
}


export const config = {
    baseURL: apiPath(),
  };