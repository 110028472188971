export const convertDateIntoLocalDate = (utcDate) => {

    // remove +00:00 at the end of utcDate if exist and replace with Z
    
    if (utcDate === undefined) {
        return '';
    }else {
        if (utcDate.includes('+00:00')) {
            utcDate = utcDate.replace('+00:00', '');
        }
    }
    // Create a new Date object using the provided server date in UTC
    const newDate = new Date(utcDate + 'Z'); // Adding 'Z' ensures the date is treated as UTC

    // Define the options for date and time formatting
    const dateOptions = {
        year: '2-digit',    // Two-digit year
        month: '2-digit',   // Two-digit month
        day: '2-digit'      // Two-digit day
    };
    const timeOptions = {
        hour: '2-digit',    // Two-digit hour
        minute: '2-digit',  // Two-digit minute
        second: '2-digit',  // Two-digit second
        hour12: true        // Use 12-hour time format with AM/PM
    };

    // Format the date and time with the specified options
    const localDateString = newDate.toLocaleDateString(undefined, dateOptions);  // Use user's locale for date
    const localTimeString = newDate.toLocaleTimeString(undefined, timeOptions);  // Use user's locale for time

    // Convert AM/PM part to uppercase
    const finalTimeString = localTimeString.replace(/am|pm/i, (match) => match.toUpperCase());

    return localDateString + " " + finalTimeString;
};

export const clearLocalStorageItems = () => {
    const keys = ['eptChannels', 'forumIds', 'selectedForumId', 'selectForumName'];
    keys.forEach(key => {
        localStorage.removeItem(key);
    });
};

export const convertLocalToUTCTimestamp = (localDate) => {
    // Create a new Date object from the input date string
    const date = new Date(localDate);

    // Convert the date to UTC by subtracting the timezone offset
    const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    // Return the Unix timestamp in seconds
    return utcDate;
};

export const convertTimeStampToUnix = (timestamp) => {
    // console.log('Timestamp:', timestamp);
    const time = convertLocalToUTCTimestamp(timestamp);
    // console.log('Time:', time);
    return Math.floor(new Date(time).getTime() / 1000);
}
