import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Col, Container, Dropdown, Row, Form } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Accordion } from "react-bootstrap";
import { config } from "../../config";
import Select from "react-select";
import { AppContext } from "../../AppContext/Context";
import Modal from "react-bootstrap/Modal";
import { api } from "../../API";
import Button from "react-bootstrap/Button";
import Loader from "../loader";
import Ellipsis from "../../utils/ellipsis/Ellipsis";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import SweetLayout from "../../Layout/SweetLayout";

const ChannelsDetailedTable = () => {
  const [data, setData] = useState({});
  const [kscForDropdown, setKscForDropdown] = useState({});
  const [newPayload, setNewPayload] = useState();
  const [hideInputAtIndex, setHideInputAtIndex] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [editedArrays, setEditedArrays] = useState({});
  const [editableActive, setEditableActive] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [isActive, setIsActive] = useState(data?.status);
  const [selectedValue, setSelectedValue] = useState(data.type);
  const [newName, setNewName] = useState(data.name || "");
  const [newGenerate, setNewGenerate] = useState(data.generate || "");
  const [newUrl, setNewUrl] = useState(data.url || "");
  const [newConfidential, setNewConfidential] = useState(data.confidential);
  const [selectedType, setSelectedType] = useState(data.type || "");
  const { id } = useParams();
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const [editMode, setEditMode] = useState(false);
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const { setIsLoad, isLoad } = useContext(AppContext);
  const handleUnauthorized = useHandleUnauthorized();

  const [editableFields, setEditableFields] = useState({
    name: false,
    url: false,
    cn: false,
    confidential: false,
    type: false,
    owner_account_id: false,
    sourceDetails: false,
    ksc: false,
    accepted_origin_list: false,
    other_fields: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    url: "",
    confidential: "",
    // CompanyName: "",
    type: data.type,
    // selected_ksc_id: selectedOption,
    owner_account_id: "N/A",
    channel_details: {},
    status: data.status,
  });
  const [showModal, setShowModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const nav = useNavigate();

  const showGenericDeleteModal = () => {
    setShowDelModal(true);
  };

  const handleCloseDelModal = () => {
    setShowDelModal(false);
  };

  const handleDelForChannel = async () => {
    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.delete(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        // setShowModal(false);
        nav("/channel");
      } catch (error) {
        console.error("API request error:", error.message);
      }
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = async () => {
    setShowModal(false);
  };

  const DropdownForType = function ({ newType, handleNewType }) {
    return (
      <Dropdown onSelect={handleNewType}>
        <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
          {newType}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="Chat">Chat</Dropdown.Item>
          <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
          <Dropdown.Item eventKey="Community Forum">
            Community Forum
          </Dropdown.Item>
          <Dropdown.Item eventKey="Hubspot">Hubspot</Dropdown.Item>
          <Dropdown.Item eventKey="Zendesk">Zendesk</Dropdown.Item>
          <Dropdown.Item eventKey="Slack">Slack</Dropdown.Item>
          <Dropdown.Item eventKey="ept-ai-app">ept-ai-app</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoad(true);
        const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setData(response.data);
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };

    const fetchData2 = async () => {
      if (!isLoading) {
        setIsLoad(true);
        const endpoint = `${config.baseURL}/admin/v1/ksc`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setKscForDropdown(response);
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };
    fetchData2();
    fetchData();
  }, [isLoading, setIsLoad, id, getIdTokenClaims, handleUnauthorized]);
  useEffect(() => {
    if (data) {
      setNewConfidential(data.confidential);
    }
  }, [data]);
  const handleEditClick = (fieldName, value) => {
    if (fieldName === "ksc") {
      setEditMode(true);
    }
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: true,
    }));
    setEditedValues((prev) => ({ ...prev, [fieldName]: value }));
  };
  useEffect(() => {}, [formData]);
  const handleSaveArrayItem = (fieldName, index) => {
    const updatedChannelDetails = { ...data.channel_details };
    updatedChannelDetails[fieldName] = editedValues[fieldName];

    setData((prevData) => ({
      ...prevData,
      channel_details: updatedChannelDetails,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      channel_details: updatedChannelDetails,
    }));

    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));
    toggleEditedArray(fieldName);
  };
  const handleSaveKscClick = async (fieldName, value) => {
    if (fieldName === "ksc") {
      setEditMode(false);
    }
    toggleEditedArray(fieldName);
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
    handleSaveActiveClick();

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      // handleSaveArrayItem(fieldName);
      const response = await api.put(
        `${config.baseURL}/admin/v1/channel/${id}`,
        {
          ksc_id: selectedOption,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }

    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };
  const handleSaveClick = async (fieldName, value) => {
    if (fieldName === "ksc") {
      setEditMode(false);
    }
    toggleEditedArray(fieldName);
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
      other_fields: false,
    }));
    handleSaveActiveClick();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: editedValues[fieldName],
    }));

    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));

    let updatedChannelDetails = { ...data.channel_details };
    if (updatedChannelDetails.hasOwnProperty(fieldName)) {
      updatedChannelDetails[fieldName] = editedValues[fieldName];
    }

    setData((prevData) => ({
      ...prevData,
      channel_details: updatedChannelDetails,
    }));

    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: "",
    }));
    try {
      const getidToken = await getIdTokenClaims();
      setIsLoad(true);
      const idToken = getidToken.__raw;
      // handleSaveArrayItem(fieldName);
      const response = await api.put(
        `${config.baseURL}/admin/v1/channel/${id}`,
        {
          ...formData,
          channel_details: updatedChannelDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoad(false);
    }

    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };

  const handlesave = async (fieldName) => {
    if (fieldName === "ksc") {
      setEditMode(false);
    }
    toggleEditedArray(fieldName);
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
      other_fields: false,
    }));

    handleSaveActiveClick();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: editedValues[fieldName],
    }));

    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));

    let updatedChannelDetails = { ...data.channel_details };
    if (updatedChannelDetails.hasOwnProperty(fieldName)) {
      updatedChannelDetails[fieldName] = editedValues[fieldName];
    }

    setData((prevData) => ({
      ...prevData,
      channel_details: updatedChannelDetails,
    }));

    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: "",
    }));
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const response = await api.put(
        `${config.baseURL}/admin/v1/channel/${id}`,
        {
          ...formData,
          channel_details: newPayload ? newPayload : updatedChannelDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setNewPayload(undefined);
    } catch (error) {
      console.error("Error:", error);
    }

    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };
  const handleSelectChange = (selected) => {
    setSelectedOption(selected.Ksc_id);
  };
  const handleNewType = (e) => {
    setSelectedType(e);
  };
  const handleNewName = (e) => {
    setNewName(e.target.value);
  };
  const handleNewGenerate = (e) => {
    setNewGenerate(e.target.value);
  };

  const handleNewConfidential = (e) => {
    setNewConfidential(e.target.checked, newConfidential);
  };
  const handleNewUrl = (e) => {
    setNewUrl(e.target.value);
  };
  useEffect(() => {
    if (data) {
      setFormData({
        name: newName ? newName : data.name,
        generate: newGenerate ? newGenerate : data.generate,
        url: newUrl ? newUrl : data.url,
        confidential: newConfidential ? newConfidential : newConfidential,
        type: selectedType ? selectedType : data.type,
        // CompanyName: data.company_name,
        status: isActive ? "active" : "not active",
        // selected_ksc_id: selectedOption,
        owner_account_id:
          data.owner_account_id !== undefined && data.owner_account_id !== null
            ? data.owner_account_id
            : data.owner_account_id === 0
            ? 0
            : "N/A",
        channel_details: { ...data.channel_details },
      });
    }
  }, [
    data,
    selectedValue,
    isActive,
    selectedOption,
    newUrl,
    newConfidential,
    newName,
    newGenerate,
    selectedType,
  ]);

  const handleChange = (e, fieldName, nestedField = null, index = null) => {
    if (nestedField !== null) {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          [nestedField]: e.target.value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }));
    }
  };

  const toggleEditedArray = (fieldName) => {
    setEditedArrays((prevEditedArrays) => ({
      ...prevEditedArrays,
      [fieldName]: !prevEditedArrays[fieldName],
    }));
  };

  const handleEditArray = (fieldName) => {
    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: [...data.channel_details[fieldName]],
    }));
    toggleEditedArray(fieldName);
    if (fieldName === "accepted_origin_list") {
      setEditableFields((prev) => ({
        ...prev,
        accepted_origin_list: true,
      }));
    } else {
      setEditableFields((prev) => ({
        ...prev,
        other_fields: true,
      }));
    }
  };

  const handleEditArrayItemChange = (fieldName, index, e) => {
    const updatedArray = [...editedValues[fieldName]];
    updatedArray[index] = e.target.value;
    setEditedValues((prev) => ({
      ...prev,
      [fieldName]: updatedArray,
    }));
    // setEditableFields((prev) => ({
    // ...prev,
    // accepted_origin_list: prev.accepted_origin_list.map((item, i) =>
    // i === index ? true : item
    // ),
    // }));
  };

  const handleAddOriginItem = () => {
    const updatedData = { ...data };
    updatedData.channel_details.accepted_origin_list.push("");
    setData(updatedData);
  };
  const handleDeleteArrayItem = async (key, index) => {
    let updatedChannelDetails;
    const confirmed = window.confirm("Do you want to delete this?");
    if (confirmed) {
      setData((prevData) => {
        updatedChannelDetails = { ...prevData.channel_details };
        if (Array.isArray(updatedChannelDetails[key])) {
          updatedChannelDetails[key] = updatedChannelDetails[key].filter(
            (item, i) => i !== index
          );
        } else {
          delete updatedChannelDetails[key];
        }
        return {
          ...prevData,
          channel_details: updatedChannelDetails,
        };
      });
      if (key === "accepted_origin_list") {
        setEditableFields((prev) => ({
          ...prev,
          accepted_origin_list: false, // Set entire array to true
        }));
      } else {
        setEditableFields((prev) => ({
          ...prev,
          other_fields: false,
        }));
      }
      setEditedValues((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    }
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      setNewPayload(updatedChannelDetails);
      // handleSaveArrayItem(fieldName);
      const response = await api.put(
        `${config.baseURL}/admin/v1/channel/${id}`,
        {
          ...formData,
          channel_details: updatedChannelDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setNewPayload(undefined);
    } catch (error) {
      console.error("Error:", error);
    }

    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };
  let keyIndex = 0;

  const [newKeyValuePairs, setNewKeyValuePairs] = useState([]);

  const handleAddKeyValuePair = () => {
    setNewKeyValuePairs((prevPairs) => [...prevPairs, { key: "", value: "" }]);
  };

  const handleInputChange = (index, keyOrValue, newValue) => {
    setNewKeyValuePairs((prevPairs) => {
      const updatedPairs = [...prevPairs];
      updatedPairs[index][keyOrValue] = newValue;
      return updatedPairs;
    });
  };

  const handleSaveClick1 = async (key, index) => {
    const updatedFormData = { ...formData };
    const updatedChannelDetails = { ...updatedFormData.channel_details };
    const pair = newKeyValuePairs.find((pair) => pair.key === key);
    if (pair) {
      updatedChannelDetails[key] = pair.value;
      updatedFormData.channel_details = updatedChannelDetails;
      setFormData(updatedFormData);
      setHideInputAtIndex((prevHideInput) => {
        const updatedHideInput = [...prevHideInput];
        updatedHideInput[index] = true;
        return updatedHideInput;
      });

      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        // handleSaveArrayItem(fieldName);
        const response = await api.put(
          `${config.baseURL}/admin/v1/channel/${id}`,
          {
            ...formData,
            channel_details: updatedChannelDetails,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.error(`Key '${key}' not found in newKeyValuePairs.`);
    }

    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channel/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };

  const handleDeleteArrayItem1 = (key, index) => {
    const updatedPairs = [...newKeyValuePairs];
    updatedPairs.splice(index, 1);
    setNewKeyValuePairs(updatedPairs);
  };

  const renderDetail = (details) => {
    return Object.entries(details).map(([key, value], index) => {
      const uniqueKey = keyIndex++;

      if (Array.isArray(value)) {
        const isEdited = editedArrays[key] || false;
        if (key === "accepted_origin_list") {
          return (
            <div key={uniqueKey}>
              <strong>{key}: </strong>
              {value.map((item, index) => (
                <div key={index}>
                  <input
                    readOnly={!editableFields.accepted_origin_list}
                    type="text"
                    style={{
                      border: editableFields.accepted_origin_list
                        ? "1px solid #ccc"
                        : "none",
                      outline: "none",
                    }}
                    value={editedValues[key] ? editedValues[key][index] : item}
                    onChange={(e) => handleEditArrayItemChange(key, index, e)}
                  />
                  {editedValues[key] !== undefined && (
                    <i
                      className="fa fa-trash"
                      onClick={() => {
                        handleDeleteArrayItem(key, index);
                        // handlesave(key);
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                  )}
                </div>
              ))}

              {editedValues[key] === undefined && showEditButton && (
                <>
                  <i
                    className="fa fa-pen-to-square"
                    onClick={() => handleEditArray(key)}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  ></i>
                </>
              )}
              {editedValues[key] !== undefined && (
                <>
                  <i
                    className="fa fa-save"
                    onClick={() => handlesave(key)}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  ></i>
                  {/* <i
className="fa fa-plus-square-o"
aria-hidden="true"
onClick={handleAddOriginItem}
style={{ cursor: "pointer", marginLeft: "5px" }}></i> */}
                </>
              )}
            </div>
          );
        } else {
          return (
            <div key={uniqueKey}>
              <strong>{key}: </strong>
              {value.map((item, index) => (
                <div key={index}>
                  <input
                    readOnly={!editableFields.other_fields}
                    type="text"
                    style={{
                      border: isEdited ? "1px solid #ccc" : "none",
                      outline: "none",
                    }}
                    value={editedValues[key] ? editedValues[key][index] : item}
                    onChange={(e) => handleEditArrayItemChange(key, index, e)}
                  />
                </div>
              ))}
              {editedValues[key] === undefined && showEditButton && (
                <i
                  className="fa fa-pen-to-square"
                  onClick={() => handleEditArray(key)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                ></i>
              )}
              {editedValues[key] !== undefined && (
                <>
                  {" "}
                  <i
                    className="fa fa-save"
                    onClick={() => handleSaveClick(key)}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  ></i>
                  <i
                    className="fa fa-trash"
                    onClick={() => handleDeleteArrayItem(key, index)}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  ></i>
                </>
              )}
            </div>
          );
        }
      } else if (typeof value === "object" && value !== null) {
        return (
          <div key={uniqueKey}>
            <strong>{key}: </strong>
            {renderDetail(value)}
          </div>
        );
      } else {
        return (
          <div key={uniqueKey}>
            <strong>{key}: </strong>
            {value}

            {editedValues[key] !== undefined ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    <input
                      className="w-100"
                      type="text"
                      value={editedValues[key]}
                      onChange={(e) => handleEditClick(key, e.target.value)}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <i
                      className="fa fa-save"
                      onClick={() => handleSaveClick(key)}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                    <i
                      className="fa fa-trash"
                      onClick={() => handleDeleteArrayItem(key, index)}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                  </div>
                </div>
              </>
            ) : (
              showEditButton && (
                <i
                  className="fa fa-pen-to-square"
                  onClick={() => handleEditClick(key, value)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                ></i>
              )
            )}
          </div>
        );
      }
    });
  };

  const renderDetails = (details) => {
    if (!details || typeof details !== "object") {
      console.error("Invalid details:", details);
      return null;
    }

    const renderValue = (key, value) => {
      if (Array.isArray(value)) {
        return (
          <ul key={key}>
            <strong>{key}: </strong>
            {value.map((item, index) => (
              <li key={index}>
                {typeof item === "object" && item !== null ? (
                  renderDetails(item)
                ) : (
                  <input
                    readOnly
                    type="text"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    value={item !== null && item !== undefined ? item : "null"}
                  />
                )}
              </li>
            ))}
          </ul>
        );
      } else if (typeof value === "object" && value !== null) {
        return (
          <ul key={key}>
            <strong>{key}: </strong>
            {renderDetails(value)}
          </ul>
        );
      } else {
        return (
          <li key={key}>
            <strong>{key}: </strong>
            {value !== null && value !== undefined ? value : ""}
          </li>
        );
      }
    };

    return (
      <ul>
        {Object.entries(details).map(([key, value]) => (
          <li key={key}>{renderValue(key, value)}</li>
        ))}
      </ul>
    );
  };

  const details =
    data && data.channel_details ? renderDetails(data.channel_details) : null;
  const detail =
    data && data.channel_details ? renderDetail(data.channel_details) : null;

  const handleEditActiveClick = () => {
    setEditableActive(true);
  };

  const handleSaveActiveClick = () => {
    setEditableActive(false);
  };

  const handleCheckboxChange = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };
  const ept_config =
    data && data?.ept_config
      ? Object.entries(data?.ept_config).map(([key, value]) => (
          <div key={key}>
            <strong>{key}: </strong>
            {value}
          </div>
        ))
      : null;
      const ksc_details = (
        <ul>
          {data && data?.ksc
            ? Object.entries(data?.ksc).map(([key, value]) => (
                <li key={key}>
                  <div className="mt-3">
                    <strong>{key}: </strong>
                    {Array.isArray(value) && key === "kss" ? (
                      value.join(", ")
                    ) : key === "confidential" ? (
                      value.toString()
                    ) : key === "ksc_id" ? (
                      <Link to={`/ksc-details/${value}`}>{value}</Link>
                    ) : (
                      value
                    )}
                  </div>
                </li>
              ))
            : null}
        </ul>
      );
  function channelDetailsModal() {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Channel Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <i
                className="fa fa-plus-square-o"
                aria-hidden="true"
                onClick={handleAddKeyValuePair}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              ></i>
            </div>

            {detail}
            {newKeyValuePairs.map((pair, index) => (
              <div key={index}>
                {!hideInputAtIndex[index] && (
                  <>
                    <input
                      type="text"
                      value={pair.key}
                      onChange={(e) =>
                        handleInputChange(index, "key", e.target.value)
                      }
                      placeholder="Enter key"
                    />
                    :
                    <input
                      type="text"
                      value={pair.value}
                      onChange={(e) =>
                        handleInputChange(index, "value", e.target.value)
                      }
                      placeholder="Enter value"
                    />
                    {editedValues[pair.key] === undefined && (
                      <>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick1(pair.key, index)}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                        <i
                          className="fa fa-trash"
                          onClick={() =>
                            handleDeleteArrayItem1(pair.key, index)
                          }
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
          </Modal.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseModal}>
Save Changes
</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <SweetLayout>
    <Container fluid>
      <div>
        {isLoad && (
          <div
            style={{
              position: "absolute",
              // top: "150px",
              left: "60%",
              transform: "translate(-50%, -50%)",
              // height: "300px",
              // border: "1px solid red",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <div
                onClick={() => nav(-1)}
                className="fe-5 fw-bold"
                style={{ cursor: "pointer" }}
              >
                <span className="bi bi-chevron-left"></span> Back
              </div>
              <div>
                <h1>Channel</h1>
              </div>
              <div>
                <h2>Channel ID: {data.channel}</h2>
              </div>
            </div>
            <div className="p-3">
              <Row className="d-flex aligns-item-center">
                <Col sm="12" md="12" lg="3">
                  <div>
                    <span
                      className={`custom-badge ${
                        data.status === "active" ? "active" : "inactive"
                      }`}
                    >
                      {editableActive ? (
                        <input
                          type="checkbox"
                          readOnly={!editableActive}
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                      ) : data.status === "active" ? (
                        "Active"
                      ) : (
                        "Not Active"
                      )}
                    </span>

                    {showEditButton && !editableActive && (
                      <i
                        className="fa fa-pen-to-square"
                        onClick={handleEditActiveClick}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                    {editableActive && (
                      <i
                        className="fa fa-save"
                        onClick={handleSaveClick}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <Row className="d-flex justify-content-end aligns-item-center">
                    <Col xl="3" lg="3" md="6" sm="12">
                      <p className="">
                        <strong className="me-2">Type:</strong>
                        <div className="d-flex align-items-center">
                          {editableFields?.type ? (
                            <DropdownForType
                              selectedValue={selectedValue}
                              newType={selectedType}
                              handleNewType={handleNewType}
                            />
                          ) : (
                            formData.type
                          )}

                          {/* {showEditButton && !editableFields.type && (
<i
className="fa fa-pen-to-square"
onClick={() => handleEditClick("type")}
style={{ cursor: "pointer", marginLeft: "5px" }}></i>
)}
{editableFields.type && (
<i
className="fa fa-save"
onClick={() => handleSaveClick("type")}
style={{ cursor: "pointer", marginLeft: "5px" }}></i>
)} */}
                        </div>
                      </p>
                    </Col>
                    <Col lg="3" xl="3" md="6" sm="12">
                      <p className="">
                        <strong>Company Name:</strong>
                        <div>
                          {editableFields?.cn ? (
                            <input
                              type="text"
                              defaultValue={formData.cn}
                              className="white-background-input"
                              style={{
                                border: editableFields.company_name
                                  ? "1px solid #ccc"
                                  : "none",
                                outline: "none",
                              }}
                              onChange={(e) => handleChange(e, "cn")}
                            />
                          ) : (
                            data.company_name
                          )}
                        </div>
                      </p>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="12">
                      <p className="">
                        <div className="">
                          <strong>Owner Account ID:</strong>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {editableFields?.owner_account_id ? (
                            <input
                              type="text"
                              defaultValue={data.owner_account_id || "N/A"}
                              className="white-background-input"
                              style={{
                                border: editableFields.owner_account_id
                                  ? "1px solid #ccc"
                                  : "none",
                                outline: "none",
                              }}
                              onChange={(e) =>
                                handleChange(e, "owner_account_id")
                              }
                            />
                          ) : (
                            formData.owner_account_id
                          )}
                          {showEditButton &&
                            !editableFields.owner_account_id && (
                              <i
                                className="fa fa-pen-to-square"
                                onClick={() =>
                                  handleEditClick("owner_account_id")
                                }
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                              ></i>
                            )}
                          {editableFields.owner_account_id && (
                            <i
                              className="fa fa-save"
                              onClick={() =>
                                handleSaveClick("owner_account_id")
                              }
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                            ></i>
                          )}
                        </div>
                      </p>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="12">
                      <div className="">
                        <span className="fw-bold">Generate:</span>
                        <div className="d-flex justify-content-between align-items-center">
                          <span style={{ width: "100%" }}>
                            {editableFields.generate ? (
                              <div>
                                <Select
                                  id="generate"
                                  defaultValue={data?.generate}
                                  selectedOption={data?.generate}
                                  options={[
                                    { value: "all", label: "All" },
                                    { value: "never", label: "Never" },
                                    {
                                      value: "unresponded",
                                      label: "Unresponded",
                                    },
                                    { value: "unsolved", label: "Unsolved" },
                                  ]}
                                  onChange={(selectedOption) =>
                                    handleNewGenerate(
                                      {
                                        target: { value: selectedOption.value },
                                      },
                                      "generate"
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <span>{formData.generate}</span>
                            )}
                          </span>
                          {editableFields.generate ? (
                            <span
                              className="fa fa-save"
                              onClick={() => handleSaveClick("generate")}
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            ></span>
                          ) : (
                            showEditButton && (
                              <span
                                className="fa fa-pen-to-square"
                                onClick={() => handleEditClick("generate")}
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              ></span>
                            )
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="p-3 bg-white">
              <Row>
                <Col md="12">
                  <h4 style={{ marginTop: 0 }}>
                    <strong>Data</strong>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg="5" md="12" sm="12" className="mt-1">
                  <strong>Name:</strong>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      readOnly={!editableFields.name}
                      defaultValue={formData.name}
                      className="white-background-input w-100"
                      style={{
                        border: editableFields.name ? "1px solid #ccc" : "none",
                        outline: "none",
                        // minWidth: "150px",
                        // width: "300px",
                      }}
                      onChange={(e) =>
                        editableFields.name && handleNewName(e, "name")
                      }
                    />
                    {editableFields.name ? (
                      <React.Fragment>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick("name")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </React.Fragment>
                    ) : (
                      showEditButton && (
                        <i
                          className="fa fa-pen-to-square"
                          onClick={() => handleEditClick("name")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      )
                    )}
                  </div>

                  {/* </div> */}
                </Col>
                <Col lg="3" md="12" sm="12" className="mt-1">
                  <strong>Url:</strong>
                  {data.url ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <input
                        type="text"
                        readOnly={!editableFields.url}
                        defaultValue={data.url}
                        className="white-background-input w-100"
                        style={{
                          border: editableFields.url
                            ? "1px solid #ccc"
                            : "none",
                          outline: "none",
                          // minWidth: "150px",
                          // width: "300px",
                        }}
                        onChange={(e) =>
                          editableFields.url && handleNewUrl(e, "url")
                        }
                      />
                      {editableFields.url ? (
                        <React.Fragment>
                          <i
                            className="fa fa-save"
                            onClick={() => handleSaveClick("url")}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          ></i>
                        </React.Fragment>
                      ) : (
                        showEditButton && (
                          <i
                            className="fa fa-pen-to-square"
                            onClick={() => handleEditClick("url")}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          ></i>
                        )
                      )}
                    </div>
                  ) : (
                    // data && (
                    <div className="no-url-message">This id has no URL</div>
                    // )
                  )}
                  {/* </div> */}
                </Col>
                <Col lg="2" md="12" sm="12" className="mt-1">
                  <strong>Confidential:</strong>
                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <input
                      type="checkbox"
                      disabled={!editableFields.confidential}
                      checked={newConfidential}
                      className="white-background-input"
                      style={{
                        border: editableFields.confidential
                          ? "1px solid #ccc"
                          : "none",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        editableFields.confidential &&
                        handleNewConfidential(e, "confidential")
                      }
                    />
                    {editableFields.confidential ? (
                      <React.Fragment>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick("confidential")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </React.Fragment>
                    ) : (
                      showEditButton && (
                        <i
                          className="fa fa-pen-to-square"
                          onClick={() => handleEditClick("confidential")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      )
                    )}
                  </div>
                </Col>
                <Col lg="2" md="12" sm="12" className="mt-1">
                  <strong>Channel ID:</strong>{" "}
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{data.channel}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {" "}
                  <strong> Channel Details </strong>
                </Accordion.Header>
                <Accordion.Body>
                  {/* <Button variant="primary" onClick={handleOpenModal}>
Edit Channel Details
</Button> */}
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {showEditButton && (
                      <i
                        className="fa fa-pen-to-square"
                        onClick={handleOpenModal}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                  </div>
                  {channelDetailsModal()}
                  {details}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        {/* <Row className="mt-4">
<Col>
<Accordion>
<Accordion.Item eventKey="0">
<Accordion.Header>
{" "}
<strong> EPT Config </strong>
</Accordion.Header>
<Accordion.Body>{ept_config}</Accordion.Body>
</Accordion.Item>
</Accordion>
</Col>
</Row> */}

        <Row className="mt-4">
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {" "}
                  <strong> KSC </strong>
                </Accordion.Header>
                <Accordion.Body>
                  <div style={{ maxWidth: "250px", maxHeight: "100px" }}>
                    {editMode && (
                      <Select
                        style={{ maxHeight: "200px", overflow: "auto" }}
                        options={kscForDropdown.data?.map((record, index) => ({
                          label: `${record.name}(${record.ksc_id})`,
                          key: index,
                          Ksc_id: record.ksc_id,
                        }))}
                        onChange={handleSelectChange}
                        value={kscForDropdown.data?.find(
                          (option) => option.Ksc_id === selectedOption
                        )}
                      />
                    )}

                    {showEditButton && !editableFields.ksc && (
                      <i
                        className="fa fa-pen-to-square"
                        onClick={() => handleEditClick("ksc")}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                    {editableFields.ksc && (
                      <i
                        className="fa fa-save"
                        onClick={() => handleSaveKscClick("ksc")}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                  </div>

                  {ksc_details}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {showEditButton && (
              <div className="d-flex justify-content-end mt-2">
                <Button variant="danger" onClick={showGenericDeleteModal}>
                  Delete Channel
                </Button>
                <DeleteConfirmationModal
                  show={showDelModal}
                  handleClose={handleCloseDelModal}
                  handleDelete={handleDelForChannel}
                  itemName={formData.name}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Container>
    </SweetLayout>
  );
};

export default ChannelsDetailedTable;
