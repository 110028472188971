import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import { Col, Row, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../API";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import NavigationBackButton from "../navigateback/NavigationBackButton";
import SweetLayout from "../../Layout/SweetLayout";

function AddKs(props) {
  const { getIdTokenClaims, isLoading } = useAuth0();
  const [selectedType, setSelectedType] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedConfidential, setSelectedConfidential] = useState(false);
  const [sourceName, setSourceName] = useState("");
  const [sourceDiscription, setSourceDiscription] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [zendeskUrl, setZendeskUrl] = useState("");
  const [githubOrgName, setGithubOrgName] = useState("");
  const [bucket, setBucket] = useState("");
  const [key, setKey] = useState("");
  const [selectGrey, setSelectGrey] = useState(false);
  const [displayChannelList, setDisplayChannelList] = useState(false);
  const [displayWebsiteUrl, setDisplayWebsiteUrl] = useState(false);
  const [displayZendeskUrl, setDisplayZendeskUrl] = useState(false);
  const [displayGithubOrgName, setDisplayGithubOrgName] = useState(false);
  const [displayCustomDataSource, setDisplayCustomDataSource] = useState(false);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState();

  const handleChannelChange = (selectedOptions) => {
    setSelectedChannels(selectedOptions);
  };
  const handleTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions);
  };
  // const openNewSourceModel = (e) => {
  //   e.preventDefault();
  //   document.getElementById("request_new_source").classList.add("show");
  // };
  // const hideNewSourceModel = (e) => {
  //   e.preventDefault();
  //   document.getElementById("request_new_source").classList.remove("show");
  // };
  const toastHandler = (message, type) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const nav = useNavigate();
  const putKs = async (data) => {
    try {
      // Get the ID token from Auth0
      const getIdToken = await getIdTokenClaims();
      const idToken = getIdToken.__raw;
      const options = {
        method: "Post",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      const path = `/ks`;
      const url = `${config.baseURL}/admin/v1${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      const toastId = toast.success("Knowledge source added successfully!");
      setTimeout(() => {
        nav(`/ks/${result?.ks_id}`);
      }, 3000);
      document.getElementById("request_new_source").classList.remove("show");
      setSourceName("");
      setSourceDiscription("");
      setSelectedType([]);
      setSelectedChannel([]);
      setWebsiteUrl("");
      setZendeskUrl("");
      setGithubOrgName("");
      setBucket("");
      setKey("");
      setSelectedConfidential(false);
      document.getElementById("ks-submit-btn").classList.remove("disabled");
      props.fetchSources();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidUrl = (url) => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(url);
    };

    const removeUrlParams = (url) => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i;
      if (urlRegex.test(url)) {
        const urlObj = new URL(url);
        const cleanedOrigin = urlObj.origin
          .replace(/^https?:\/\//, "")
          .replace(/\/+$/, "");
        return cleanedOrigin;
      }
      return url;
    };

    if (sourceName?.length === 0) {
      toastHandler("Please input source name", "error");
      return;
    }
    if (sourceDiscription?.length === 0) {
      toastHandler("Please input source description", "error");
      return;
    }
    if (selectedType?.length === 0) {
      toastHandler("Please select source type", "error");
      return;
    }
    if (
      selectedType.value === "community_forum" &&
      selectedChannels?.length === 0
    ) {
      toastHandler("Please select channel", "error");
      return;
    }
    if (selectedType.value === "website" && websiteUrl?.length === 0) {
      toastHandler("Please input website url", "error");
      return;
    }
    if (
      selectedType.value === "zendesk_knowledge_base" &&
      zendeskUrl?.length === 0
    ) {
      toastHandler("Please input zendesk url", "error");
      return;
    }
    if (selectedType.value === "github" && githubOrgName?.length === 0) {
      toastHandler("Please input github organization name", "error");
      return;
    }
    if (selectedType.value === "aws_s3" && bucket?.length === 0) {
      toastHandler("Please input bucket", "error");
      return;
    }
    if (selectedType.value === "aws_s3" && key?.length === 0) {
      toastHandler("Please input key", "error");
      return;
    }
    if (selectedType.value === "website" && !isValidUrl(websiteUrl)) {
      toastHandler("Please enter a valid website URL", "error");
      return;
    }
    if (
      selectedType.value === "zendesk_knowledge_base" &&
      !isValidUrl(zendeskUrl)
    ) {
      toastHandler("Please enter a valid Zendesk URL", "error");
      return;
    }
    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");
    let data = {};
    data.name = sourceName;
    data.description = sourceDiscription;
    data.type = selectedType.value;
    data.confidential = selectedConfidential;
    data.source_details = data.source_details || {};
    data.owner_account_id = formData.owner_account_id;

    if (selectedType.value === "community_forum") {
      // const selectedChannel = channels.find(channel =>
      //   selectedChannels.some(selected => selected.value === channel.channel)
      // );
      data.source_details.forum_id = selectedChannel
        ? selectedChannels?.value
        : null;
    }

    if (selectedType.value === "website") {
      data.source_details.url_filter = data.source_details.url_filter || [];
      const filterUrl = removeUrlParams(websiteUrl);
      data.source_details.url = websiteUrl;
      data.source_details.url_filter.push(filterUrl);
    }
    if (selectedType.value === "zendesk_knowledge_base") {
      data.source_details.zendesk_domain = zendeskUrl;
    }
    if (selectedType.value === "github") {
      data.source_details.github_org = githubOrgName;
    }
    if (selectedType.value === "aws_s3") {
      data.source_details.aws_s3 = data.source_details.aws_s3 || {};
      data.source_details.aws_s3.bucket = bucket;
      data.source_details.aws_s3.key = key;
    }

    data = JSON.stringify(data);
    putKs(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setLoadingCount((prevCount) => prevCount + 1);
        const endpoint = `${config.baseURL}/admin/v1/account`;
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          const response = await api.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setOwnerOptions(
            response.data.map((channel) => ({
              value: channel.account_id,
              label: `${channel.name} (${channel.account_id})`,
            }))
          );
        } catch (error) {
          console.error("API request error:", error.message);
        } finally {
          setLoadingCount((prevCount) => prevCount - 1);
        }
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims]);

  useEffect(() => {
    if (props.channelList && props.channelList?.length > 0) {
      setFilteredChannels(
        props.channelList
          .filter((channel) => channel.type === "community_forum")
          .map((channel) => ({
            value: channel.channel,
            label: channel.url,
          }))
      );
    }
  }, [props.channelList]);

  useEffect(() => {
    if (filteredChannels && filteredChannels?.length > 0) {
      setSelectedChannel(filteredChannels[0]);
    }
  }, [filteredChannels]);

  let endpoint = `${config.baseURL}/admin/v1/channel`;

  const [formData, setFormData] = useState({
    name: "",
    owner_account_id: "",
    channel_ids: [],
  });
  const formClear = () => {
    setSourceName("");
    setSourceDiscription("");
    setWebsiteUrl("");
    setZendeskUrl("");
    setGithubOrgName("");
    setBucket("");
    setKey("");
    setSelectedConfidential(false);
    setSelectedType([]);
    setSelectedChannel([]);
  };

  const fetchChannels = async () => {
    try {
      const getIdToken = await getIdTokenClaims();
      const idToken = getIdToken.__raw;

      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setChannels(response.data);
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, [getIdTokenClaims, isLoading]);

  const handleSelect = (channelId, isChecked) => {
    setFormData((prevFormData) => {
      const channelIdsUpdated = isChecked
        ? [...prevFormData.channel_ids, channelId]
        : prevFormData.channel_ids.filter((id) => id !== channelId);

      return {
        ...prevFormData,
        channel_ids: channelIdsUpdated,
      };
    });
  };

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      owner_account_id: selectedOption ? selectedOption.value : "",
    });
  };

  useEffect(() => {
    if (selectedType.value === "community_forum") {
      setDisplayChannelList(true);
      if (props.channelList?.length <= 1) {
        setSelectGrey(true);
      } else {
        setSelectGrey(false);
      }
    } else {
      setDisplayChannelList(false);
      setSelectGrey(false);
    }
    if (selectedType.value === "website") {
      setDisplayWebsiteUrl(true);
    } else {
      setDisplayWebsiteUrl(false);
    }
    if (selectedType.value === "zendesk_knowledge_base") {
      setDisplayZendeskUrl(true);
    } else {
      setDisplayZendeskUrl(false);
    }
    if (selectedType.value === "github") {
      setDisplayGithubOrgName(true);
    } else {
      setDisplayGithubOrgName(false);
    }
    if (selectedType.value === "aws_s3") {
      setDisplayCustomDataSource(true);
    } else {
      setDisplayCustomDataSource(false);
    }
  }, [selectedType]);
  useEffect(() => {
    const channelOptions = channels.map((channel) => ({
      value: channel.channel,
      label: channel.name,
    }));
    setFilteredChannels(channelOptions);
  }, [channels]);

  return (
    <SweetLayout>
    <div>
      <div id="request_new_source">
        <div>
          <div className="delete-user-modal-body">
            <NavigationBackButton />
            <div className="">
              <h1 className="test-account">Create Knowledge Source</h1>
            </div>
            <Row className="mb-2 mt-2">
              <Col md={6}>
                <div className="input-group d-flex flex-column">
                  <Form.Group>
                    <Form.Label htmlFor="source-name" className="fw-bold">
                      Source name:
                    </Form.Label>
                    <Form.Control
                      name="source-name"
                      placeholder=""
                      value={sourceName}
                      onChange={(e) => setSourceName(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="input-group d-flex flex-column">
                  <Form.Group>
                    <Form.Label className="fw-bold" htmlFor="source-name">
                      Description:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className=""
                      id="source-des"
                      name="source-des"
                      placeholder=""
                      value={sourceDiscription}
                      onChange={(e) => setSourceDiscription(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <div className="input-group d-flex flex-column">
                  <Form.Label className="fw-bold" htmlFor="source-type">
                    Type:
                  </Form.Label>
                  <Select
                    id="type"
                    onChange={handleTypeChange}
                    value={selectedType}
                    options={[
                      { value: "community_forum", label: "Community forum" },
                      { value: "website", label: "Website" },
                      {
                        value: "zendesk_knowledge_base",
                        label: "Zendesk knowledge base",
                      },
                      { value: "github", label: "Github" },
                      { value: "aws_s3", label: "AWS S3" },
                      {
                        value: "contributed_knowledge",
                        label: "Contributed Knowledge",
                      },
                    ]}
                    isSearchable
                    placeholder="Select Type"
                  />
                </div>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formOwnerAccountId">
                  <Form.Label className="fw-bold">Owner Account ID:</Form.Label>
                  <Select
                    id="channels"
                    value={
                      formData.owner_account_id
                        ? ownerOptions.find(
                            (option) =>
                              option.value === formData.owner_account_id
                          )
                        : null
                    }
                    onChange={handleChange}
                    options={ownerOptions}
                    placeholder="Select Owner Account ID"
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" md={6}>
                {selectedType.value === "community_forum" && (
                  <>
                    <Form.Label className="fw-bold">Channel:</Form.Label>
                    <Select
                      id="channels"
                      onChange={handleChannelChange}
                      value={selectedChannels}
                      options={filteredChannels}
                      isMulti={false} // Allow multiple selections
                      placeholder="Select Channels"
                    />
                  </>
                )}
                {displayWebsiteUrl && (
                  <div className="input-group d-flex flex-column mt-2">
                    <Form.Group>
                      <Form.Label className="fw-bold" htmlFor="source-name">
                        Website URL:
                      </Form.Label>
                      <Form.Control
                        id="website-url"
                        name="website-url"
                        placeholder=""
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                )}
                {displayZendeskUrl && (
                  <div className="input-group d-flex flex-column mt-2">
                    <Form.Group>
                      <Form.Label htmlFor="source-name" className="fw-bold">
                        Zendesk URL:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="zendesk-url"
                        name="zendesk-url"
                        placeholder=""
                        value={zendeskUrl}
                        onChange={(e) => setZendeskUrl(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                )}
                {displayGithubOrgName && (
                  <div className="input-group d-flex flex-column mt-2">
                    <Form.Group>
                      <Form.Label htmlFor="source-name" className="fw-bold">
                        Github Organization Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="github-org-name"
                        name="github-org-name"
                        placeholder=""
                        value={githubOrgName}
                        onChange={(e) => setGithubOrgName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                )}
                {displayCustomDataSource && (
                  <div>
                    <div className="input-group d-flex flex-column mt-2">
                      <Form.Group>
                        <Form.Label htmlFor="source-name" className="fw-bold">
                          Bucket:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="bucket"
                          name="bucket"
                          placeholder=""
                          value={bucket}
                          onChange={(e) => setBucket(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="input-group d-flex flex-column mt-2">
                      <Form.Group>
                        <Form.Label htmlFor="source-name" className="fw-bold">
                          Key:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="key"
                          name="key"
                          placeholder=""
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="input-group d-flex gap-2 mt-2">
                  <Form.Label className="fw-bold">Confidential</Form.Label>
                  <Form.Check
                    // style={{ paddingLeft: "20px" }}
                    id="pop-source-confidentials-1"
                    name="pop-source-confidentials"
                    checked={selectedConfidential}
                    onChange={(e) => setSelectedConfidential(e.target.checked)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="delete-user-modal-footer">
            <div className="d-flex mt-3 align-items-center gap-2">
              <Button
                variant="primary"
                id="ks-submit-btn"
                onClick={(e) => handleSubmit(e)}
              >
                Create KS
              </Button>
              <Button variant="danger" onClick={formClear}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    </SweetLayout>
  );
}
export default AddKs;
