import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Accordion, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import TableForVersions from "../modals/ModalForVersions";
import { api } from "../../API";
import Loader from "../loader";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import SweetLayout from "../../Layout/SweetLayout";

const KsDetailedTable = () => {
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [data, setData] = useState({});
  const handleUnauthorized = useHandleUnauthorized();
  const { id } = useParams();
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const { setIsLoad, isLoad } = useContext(AppContext);
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const nav = useNavigate();
  const [editableFields, setEditableFields] = useState({
    name: false,
    description: false,
    confidential: false,
    type: false,
    owner_account_id: false,
    sourceDetails: false,
  });

  const [formData, setFormData] = useState({
    name: data ? data.name : "",
    description: data ? data.description : "",
    confidential: data ? data.confidential : "",
    type: data ? data.type : "",
    owner_account_id: "N/A",
    source_details: data
      ? data.source_details
      : {
          url: "",
          block_size: 2000,
          url_filter: [],
          blocked_urls: [],
          pinecone_host: "",
          embedding_model: "",
          completions_model: "",
          context_table_name: "",
          pinecone_namespace: "",
          pinecone_api_key_id: "",
        },
  });
  useEffect(() => {
    setFormData({
      name: data ? data.name : "",
      description: data ? data.description : "",
      confidential: data ? data.confidential : "",
      type: data ? data.type : "",
      owner_account_id: "N/A",
      source_details: data
        ? data.source_details
        : {
            url: "",
            block_size: 2000,
            url_filter: [],
            blocked_urls: [],
            pinecone_host: "",
            embedding_model: "",
            completions_model: "",
            context_table_name: "",
            pinecone_namespace: "",
            pinecone_api_key_id: "",
          },
    });
  }, [data]);
  const optionsUTC = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const handleEditClick = (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: true,
    }));
  };

  const handleSaveClick = async (fieldName) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
    setIsLoad(true);

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const response = await api.put(`/admin/v1/ks/${id} `, formData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoad(false);
    }
  };

  const handleChange = (e, fieldName, nestedField = null, index = null) => {
    const { value, checked } = e.target;

    if (nestedField && index !== null) {
      setFormData((prevState) => ({
        ...prevState,
        source_details: {
          ...prevState.source_details,
          [nestedField]: prevState.source_details[nestedField]?.map(
            (item, i) => {
              if (i === index) {
                return value;
              }
              return item;
            }
          ),
        },
      }));
    } else if (nestedField) {
      setFormData((prevState) => ({
        ...prevState,
        source_details: {
          ...prevState.source_details,
          [nestedField]: value,
        },
      }));
    } else if (fieldName === "confidential") {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoad(true);
        const endpoint = `${config.baseURL}/admin/v1/ks/${id}`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setData(response.data);
          handleNestedObjectClick(response.data, "versions");
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };
    fetchData();
  }, [isLoading, id, getIdTokenClaims, handleUnauthorized]);

  const handleNestedObjectClick = (record, key) => {
    const nestedData = record[key];
    if (key === "versions") {
      setSelectedVersion(nestedData);
    }
  };

  const renderValue = (key, value) => {
    if (
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return "N/A";
    } else if (value === false || value === true) {
      return value.toString();
    } else if (Array.isArray(value)) {
      return (
        <ul>
          {value?.map((item, index) => (
            <li key={index}>{renderValue(index, item)}</li>
          ))}
        </ul>
      );
    } else if (key === "url_filter" || key === "blocked_urls") {
      return value?.map((item, index) => (
        <input
          key={index}
          type="text"
          value={item}
          readOnly={!editableFields.sourceDetails}
          className="white-background-input"
          style={{
            border: editableFields.sourceDetails ? "1px solid #ccc" : "none",
            outline: "none",
            marginBottom: "5px",
          }}
          onChange={(e) =>
            editableFields.sourceDetails &&
            handleChange(e, "source_details", key, index)
          }
        />
      ));
    } else if (typeof value === "object") {
      if (key === "old_stats") {
        return (
          <div>
            <ul>
              {Object.entries(value)?.map(([innerKey, innerValue]) => (
                <li key={innerKey}>
                  <strong>{innerKey}:</strong>
                  {renderValue(innerKey, innerValue)}
                </li>
              ))}
            </ul>
          </div>
        );
      } else if (key === "source_details") {
        return (
          <Accordion className="w-100">
            <Accordion.Item eventKey="sourceDetailsAccordion" className="w-100">
              <Accordion.Header className="w-100">
                <strong>{key.replace(/_/g, " ")}</strong>
              </Accordion.Header>
              <Accordion.Body>
                {editableFields.sourceDetails ? (
                  <i
                    className="fa fa-save"
                    onClick={() => handleSaveClick("sourceDetails")}
                    style={{ cursor: "pointer" }}
                  ></i>
                ) : (
                  showEditButton && (
                    <i
                      className="fa fa-pen-to-square"
                      onClick={() => handleEditClick("sourceDetails")}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                  )
                )}
                <ul className="d-flex flex-column gap-2">
                  {Object.entries(value)?.map(([innerKey, innerValue]) => (
                    <li key={innerKey}>
                      {innerKey === "url_filter" ||
                      innerKey === "blocked_urls" ? (
                        <Accordion className="w-100">
                          <Accordion.Item
                            eventKey={`${key}_${innerKey}`}
                            className="w-100"
                          >
                            <Accordion.Header>
                              <strong>{innerKey}</strong>
                            </Accordion.Header>
                            <Accordion.Body>
                              {innerValue?.map((item, index) => (
                                <div key={index}>
                                  <input
                                    type="text"
                                    readOnly={!editableFields.sourceDetails}
                                    value={
                                      formData.source_details &&
                                      formData.source_details[innerKey] &&
                                      formData.source_details[innerKey][index]
                                        ? formData.source_details[innerKey][
                                            index
                                          ]
                                        : ""
                                    }
                                    className="white-background-input"
                                    style={{
                                      border: editableFields.sourceDetails
                                        ? "1px solid #ccc"
                                        : "none",
                                      outline: "none",
                                    }}
                                    onChange={(e) =>
                                      editableFields.sourceDetails &&
                                      handleChange(e, key, innerKey, index)
                                    }
                                  />
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <div className="d-flex gap-4">
                          <strong>{innerKey}:</strong>{" "}
                          <input
                            type="text"
                            readOnly={!editableFields.sourceDetails}
                            value={
                              formData.source_details
                                ? formData.source_details[innerKey]
                                : ""
                            }
                            className="white-background-input"
                            style={{
                              border: editableFields.sourceDetails
                                ? "1px solid #ccc"
                                : "none",
                              outline: "none",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              editableFields.sourceDetails &&
                              handleChange(e, "source_details", innerKey)
                            }
                          />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else {
        return (
          <Accordion className="w-100">
            <Accordion.Item eventKey={key} className="w-100">
              <Accordion.Header className="w-100">
                <strong>{key}</strong>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  {Object.entries(value)?.map(([innerKey, innerValue]) => (
                    <li key={innerKey}>
                      <>
                        <strong style={{ marginRight: "8px" }}>
                          {innerKey}:
                        </strong>
                        {renderValue(innerKey, innerValue)}
                      </>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      }
    } else if (typeof value === "string" && value.includes(",")) {
      const parts = value.split(",");
      return (
        <ul>
          {parts?.map((part, index) => (
            <li key={index}>{part}</li>
          ))}
        </ul>
      );
    } else {
      if (key === "last_updated_date") {
        const dateObject = new Date(parseInt(value) * 1000);
        return dateObject.toLocaleString("en-US", optionsUTC);
      } else {
        return value;
      }
    }
  };

  const renderCell = (key, value) => {
    return renderValue(key, value);
  };

  return (
    <SweetLayout>
      <div
        style={{ width: "100%", maxWidth: "100vw", margin: "0", padding: "0" }}
      >
        {isLoad && (
          <div
            style={{
              position: "absolute",
              // top: "150px",
              left: "60%",
              transform: "translate(-50%, -50%)",
              // height: "300px",
              // border: "1px solid red",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        )}

        <Row>
          <Col md="6" sm="12">
            <div>
              <div
                onClick={() => nav(-1)}
                className="fe-5 fw-bold"
                style={{ cursor: "pointer" }}
              >
                <span className="bi bi-chevron-left"></span> Back
              </div>
              <h1 className="mb-0">KS</h1>
              <p>Knowledge Source</p>
            </div>
          </Col>
          <Col md="6" sm="12">
            <div className="d-flex justify-content-md-end justify-content-sm-start ">
              <h2 className="">KS ID: {data.ks_id}</h2>
            </div>
          </Col>
        </Row>
        <div>
          <Row className="align-items-center mb-2">
            <Col sm="12" md="12" lg="3" className="mt-2">
              <div className="d-flex align-items-center gap-2">
                <div className="fw-bold">Status:</div>
                <span style={{ textTransform: "capitalize" }}>
                  {data?.status}
                </span>
              </div>
            </Col>
            <Col sm="12" md="12" lg="9" className="mt-2">
              <Row>
                <Col
                  sm="6"
                  md="6"
                  lg="3"
                  className="d-flex justify-content-lg-end"
                >
                  <p>
                    <strong>Type:</strong>{" "}
                    {editableFields.type ? (
                      <input
                        type="text"
                        value={formData.type}
                        className="white-background-input"
                        style={{
                          border: editableFields.type
                            ? "1px solid #ccc"
                            : "none",
                          outline: "none",
                        }}
                        onChange={(e) => handleChange(e, "type")}
                      />
                    ) : (
                      formData.type
                    )}
                  </p>
                </Col>
                <Col sm="6" md="6" lg="3">
                  <p style={{ marginRight: "10px" }}>
                    <strong>Created at:</strong>{" "}
                    {data?.created_at
                      ? new Date(data.created_at).toLocaleString()
                      : null}
                  </p>
                </Col>
                <Col sm="6" md="6" lg="3">
                  <p style={{ marginRight: "10px" }}>
                    <strong>Updated at:</strong>{" "}
                    {data?.updated_at
                      ? new Date(data.updated_at).toLocaleString()
                      : null}
                  </p>
                </Col>
                <Col sm="6" md="6" lg="3">
                  <p style={{ marginRight: "10px" }}>
                    <strong>Owner Account ID:</strong>{" "}
                    {editableFields?.owner_account_id ? (
                      <input
                        type="text"
                        // value={formData.owner_account_id}
                        className="white-background-input"
                        defaultValue={data.owner_account_id}
                        style={{
                          border: editableFields.owner_account_id
                            ? "1px solid #ccc"
                            : "none",
                          outline: "none",
                        }}
                        onChange={(e) => handleChange(e, "owner_account_id")}
                      />
                    ) : data.owner_account_id ? (
                      data.owner_account_id
                    ) : (
                      "N/A"
                    )}
                    {showEditButton && !editableFields.owner_account_id && (
                      <i
                        className="fa fa-pen-to-square"
                        onClick={() => handleEditClick("owner_account_id")}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                    {editableFields.owner_account_id && (
                      <i
                        className="fa fa-save"
                        onClick={() => handleSaveClick("owner_account_id")}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      ></i>
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <div></div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="p-3"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <h4 style={{ marginTop: 0 }}>
              <strong>Data</strong>
            </h4>
            <Row>
              <Col sm="6" md="6" lg="4" className="mt-1">
                <div>
                  <strong>Name:</strong>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      type="text"
                      readOnly={!editableFields.name}
                      value={formData.name}
                      className="white-background-input w-100"
                      style={{
                        border: editableFields.name ? "1px solid #ccc" : "none",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        editableFields.name && handleChange(e, "name")
                      }
                    />
                    {editableFields.name ? (
                      <React.Fragment>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick("name")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </React.Fragment>
                    ) : (
                      showEditButton && (
                        <i
                          className="fa fa-pen-to-square"
                          onClick={() => handleEditClick("name")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      )
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="6" md="6" lg="4" className="mt-1">
                <div>
                  <strong>Description:</strong>
                  <div className="d-flex align-items-center  gap-2">
                    <input
                      type="text"
                      readOnly={!editableFields.description}
                      value={formData.description}
                      className="white-background-input w-100"
                      style={{
                        border: editableFields.description
                          ? "1px solid #ccc"
                          : "none",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        editableFields.description &&
                        handleChange(e, "description")
                      }
                    />
                    {editableFields.description ? (
                      <React.Fragment>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick("description")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </React.Fragment>
                    ) : (
                      showEditButton && (
                        <i
                          className="fa fa-pen-to-square"
                          onClick={() => handleEditClick("description")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      )
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="6" md="6" lg="2" className="mt-1">
                <div>
                  <strong>Confidential:</strong>
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <input
                      type="checkbox"
                      readOnly={!editableFields.confidential}
                      checked={formData.confidential}
                      className="white-background-input"
                      style={{
                        border: editableFields.confidential
                          ? "1px solid #ccc"
                          : "none",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        editableFields.confidential &&
                        handleChange(e, "confidential")
                      }
                    />
                    {editableFields.confidential ? (
                      <React.Fragment>
                        <i
                          className="fa fa-save"
                          onClick={() => handleSaveClick("confidential")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      </React.Fragment>
                    ) : (
                      showEditButton && (
                        <i
                          className="fa fa-pen-to-square"
                          onClick={() => handleEditClick("confidential")}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        ></i>
                      )
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="6" md="6" lg="2" className="mt-1">
                <div>
                  <strong>Ks_id:</strong>{" "}
                  <div style={{ display: "inline-block" }}>{data.ks_id}</div>
                </div>
              </Col>
            </Row>
          </div>

          {Object.entries(data)?.map(([key, value]) => {
            if (
              typeof value === "object" &&
              value !== null &&
              !Array.isArray(value)
            ) {
              return <span className="p-1">{renderCell(key, value)}</span>;
            }
            return null;
          })}
        </div>

        {selectedVersion && (
          <>
            <TableForVersions data={selectedVersion} />
          </>
        )}
      </div>
      </SweetLayout>
  );
};

export default KsDetailedTable;
