import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import Account from "../components/account/Account.jsx";
import Layout from "../Layout/index.jsx";
import { toast, ToastContainer } from "react-toastify";
import SweetLayout from "../Layout/SweetLayout.js";
function Home() {
  // const [firstLogin, setFirstLogin] = useState(true);
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    const fetchUserToken = async () => {
      if (!isLoading && isAuthenticated) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          localStorage.setItem("token", idTokenClaims.__raw);
        } catch (error) {
          console.error("Error fetching user token:", error);
        }
      }
    };

    fetchUserToken();
  }, [isLoading, isAuthenticated, getIdTokenClaims]);

  if (isLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <div>
      <ToastContainer />
      {!isAuthenticated ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "70vh" }}>
          <div className="mb-3">
            <img
              src="/assets/logo-black.svg"
              alt=""
              style={{ marginLeft: "0.8rem", padding: "0.7rem" }}
            />
          </div>
          <Button variant="primary" onClick={() => handleLogin()}>
            Log In
          </Button>
        </div>
      ) : (
        <div>
          <SweetLayout>
            {" "}
            <Account />
            </SweetLayout>
        </div>
      )}
    </div>
  );
}

export default Home;
