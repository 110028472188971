import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Ks from "../components/ks/Ks";
import Ksc from "../components/ksc/Ksc";
import Channel from "../components/channel/Channel";
import Home from "../pages/home";
import KscDetailedTable from "../components/ksc/KscDetails";
import ChannelDetailedTable from "../components/channel/ChannelDetails";
import KsDetails from "../components/ks/KsDetails";
import AccountDetails from "../components/account/AccountDetails";
import Layout from "../Layout/index";
import CreateAccount from "../components/account/CreateAccount";
import CreateChannel from "../components/channel/CreateChannel";
import CreateKs from "../components/ks/CreateKs";
import CreateKsc from "../components/ksc/CreateKsc";
import Companies from "../components/companies/Companies";
import UsersTab from "../components/Users/UsersTab";
import ResponseList from "../components/response/ResponseList";
import ResponseDetailPage from "../components/response/ResponseDetailPage";
import UserTabDetails from "../components/Users/UserTabDetails";
import Reporting from "../components/reporting/Reporting";
import Experiments from "../components/experiments/Experiments";


const AppRouter = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/ks"
            element={
                <Ks />
            }
          />
          <Route
            path="/ksc"
            element={
                <Ksc />
            }
          />
          <Route
            path="/ksc-details/:id"
            element={
                <KscDetailedTable />
            }
          />
          <Route
            path="/channel"
            element={
                <Channel />
            }
          />
          <Route
            path="/channel/:id"
            element={
                <ChannelDetailedTable />
            }
          />
          <Route
            path="/ks/:id"
            element={
                <KsDetails />
            }
          />
          <Route
            path="/account/:id"
            element={
                <AccountDetails />
            }
          />
          <Route
            path="/create-account"
            element={
                <CreateAccount />
            }
          />
          <Route
            path="/create-channel"
            element={
                <CreateChannel />
            }
          />
          <Route
            path="/create-ks"
            element={
                <CreateKs />
            }
          />
          <Route
            path="/create-ksc"
            element={
                <CreateKsc />
            }
          />
          <Route
            path="/company"
            element={
                <Companies />
            }
          />
          <Route
            path="/users"
            element={
                <UsersTab />
            }
          />
          <Route
            path="/users/details"
            element={
                <UserTabDetails />
            }
          />
          <Route
            path="/response"
            element={
                <ResponseList />
            }
          />
          <Route
            path="/response/:id"
            element={
                <ResponseDetailPage />
            }
          />
          <Route
            path="/reporting"
          element={
              <Reporting />
          }
           />
           <Route
           path="/experiments"
            element={
                <Experiments />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
