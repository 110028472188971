import React from 'react';
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap';

const GroupedData = ({ data, keyGroups }) => {
  // Initialize an object to hold grouped data by key prefix
  const groupedData = {};

  // Iterate over the provided keyGroups (which has startingKeyName and its heading)
  keyGroups.forEach(({ startingKeyName, heading }) => {
    groupedData[heading] = Object.entries(data).filter(([key]) =>
      key.startsWith(startingKeyName)
    );
  });

  // Filter out the data that does not belong to any provided keyGroup
  const otherData = Object.entries(data).filter(
    ([key]) => !keyGroups.some(({ startingKeyName }) => key.startsWith(startingKeyName))
  );

  return (
    <ListGroup.Item>
      {/* Render non-grouped data */}
      {otherData.map(([key, value]) => {
        if (key === 'name') {
          return (
            <div key={key}>
              <strong>{key}: </strong>
              <span className="text-info">{value}</span>
            </div>
          );
        } else if (key === 'description') {
          return (
            <div key={key}>
              <strong>{key}: </strong>
              <span>{value.length > 100 ? `${value.substring(0, 100)}...` : value}</span>
            </div>
          );
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          return (
            <div key={key}>
              <strong>{key}: </strong>
              <ul>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <li key={subKey}>
                    <strong>{subKey}: </strong>
                    {typeof subValue === 'string' && subValue.startsWith('<') ? (
                      <span dangerouslySetInnerHTML={{ __html: subValue }} />
                    ) : (
                      subValue
                    )}
                  </li>
                ))}
              </ul>
            </div>
          );
        } else if (typeof value === 'string' && value.startsWith('<')) {
          return (
            <div key={key}>
              <strong>{key}: </strong>
              <span dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          );
        } else {
          return (
            <div key={key}>
              <strong>{key}: </strong>
              {value}
            </div>
          );
        }
      })}

      <Accordion>
        {/* Render grouped data with respective headings */}
        {Object.entries(groupedData).map(([heading, entries], index) => {
          if (entries.length > 0) {
            return (
              <Accordion.Item eventKey={String(index)} key={heading}>
                <Accordion.Header>{heading}</Accordion.Header>
                <Accordion.Body>
                  {entries.map(([key, value]) => (
                    <div key={key}>
                      <strong>{key}: </strong>
                      {typeof value === 'object' && !Array.isArray(value) ? (
                        <ul>
                          {Object.entries(value).map(([subKey, subValue]) => (
                            <li key={subKey}>
                              <strong>{subKey}: </strong>
                              {typeof subValue === 'string' && subValue.startsWith('<') ? (
                                <span dangerouslySetInnerHTML={{ __html: subValue }} />
                              ) : (
                                subValue
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : typeof value === 'string' ? (
                        <span dangerouslySetInnerHTML={{ __html: value }} />
                      ) : (
                        value
                      )}
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            );
          }
          return null;
        })}
      </Accordion>
    </ListGroup.Item>
  );
};

export default GroupedData;
