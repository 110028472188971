import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import SettingsLoader from "../../common/SettingsLoader";
import UserFeedbackChartCom from './UserFeedbackChartCom';
import UserFeedbackTable from './UserFeedbackTable';

function UserFeedbackContainer(props) {
    const { range, start_date, end_date, channel, setLoading, selectedAccount } = props;
    const { user } = useAuth0();
    const [data, setData] = useState(null); // Initialize data as null
    const [seriesType, setSeriesType] = useState("user_id"); // Default series type
    const [currentSeriesType, setCurrentSeriesType] = useState("user_id"); // Track the current series type for rendering
    const [errorMessage, setErrorMessage] = useState(null);
    const [seriesLoading, setSeriesLoading] = useState(false);
    const [feedbackCount, setFeedbackCount] = useState(null);

   

    const getUserFeedback = async (type, lookback, series, channel, startDate, endDate) => {
        try {
            if(series) {
                setSeriesLoading(true);
            }
            setLoading(true);
            const getData = await props.getReports(type, lookback, series, channel, startDate, endDate);
            if(getData.error) {
                setErrorMessage(getData.error);
                setData([]);
                setFeedbackCount(0);
            }else {
                setErrorMessage(null);
                setData(getData);
                setCurrentSeriesType(series); // Update the current series type when new data is fetched
                setFeedbackCount(getData.feedback_count);
            }
            setLoading(false);
            setSeriesLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setSeriesLoading(false);
        }
    }

    useEffect(() => {
        if (user && selectedAccount?.value !== null) {
            getUserFeedback("user_feedback_per_day", range, seriesType, props.channel, start_date, end_date);
        }
    }, [range, seriesType, channel, start_date, end_date, selectedAccount]); // Fetch data when the user, range, or series type changes

    
    return (
        <div>
            <h2>User Feedback</h2>
            {feedbackCount === 0 && (
                <div className="no-data">User feedback is critical for us to improve your response accuracy. Once your users have provided feedback you will be able to see that here.</div>
            )}
            <div className='chart-filter-btn-wrap' style={{ marginBottom: '20px' }}>
                {seriesLoading && (
                    <div className="chart-loading">
                        <SettingsLoader />
                    </div>
                    )}
                <button className={`${seriesType == 'user_id' ? 'active' : ''}`} onClick={() => setSeriesType("user_id")}>User ID</button>
                <button className={`${seriesType == 'feedback_type' ? 'active' : ''}`} onClick={() => setSeriesType("feedback_type")}>Feedback Type</button>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {currentSeriesType === 'user_id' ? (
                <div style={{ overflowX: 'auto' }}>
                    <UserFeedbackTable data={data} />
                </div>
            ) : (
                <UserFeedbackChartCom data={data} />
            )}
        </div>
    );
}

export default UserFeedbackContainer;
