import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { config } from "../../config";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import { AppContext } from "../../AppContext/Context";
import Loader from "../loader";
import Select from "react-select";
import debounce from "lodash/debounce";
import SweetLayout from "../../Layout/SweetLayout";

const ResponseList = () => {
  const { isLoading, getIdTokenClaims } = useAuth0();
  const {
    setIsLoad,
    isLoad,
    data,
    setData,
    setChannelData,
    channelData,
    filter,
    setFilter,
    selectedChannel,
    setSelectedChannel,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const handleUnauthorized = useHandleUnauthorized();
  const abortControllerRef = useRef(null);

  // Fetch response data with debounced effect
  useEffect(() => {
    const fetchData = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      setIsLoad(true);
      let endpoint = `${config.baseURL}/admin/v1/response_list`;

      try {
        const params = {};

        if (filter && !selectedChannel) {
          params.query = encodeURIComponent(filter);
        }
        if (filter && selectedChannel === "all") {
          params.query = encodeURIComponent(filter);
        }
        if (selectedChannel && selectedChannel !== "all") {
          params.channel_ids = selectedChannel;
        }
        if (filter && selectedChannel !== "all" && selectedChannel) {
          params.query = encodeURIComponent(filter);
          params.channel_ids = selectedChannel;
        }
        if (selectedChannel === "all") {
          endpoint = `${config.baseURL}/admin/v1/response_list`;
        }

        const getIdToken = await getIdTokenClaims();
        const idToken = getIdToken.__raw;

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          params,
          signal: controller.signal,
        });

        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        }
      } finally {
        setIsLoad(false);
      }
    };

    // Debounce fetchData function to delay API calls
    const debouncedFetchData = debounce(fetchData, 2000);
    debouncedFetchData();

    // Clean up debounced function on unmount or dependency change
    return () => {
      debouncedFetchData.cancel();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    filter,
    selectedChannel,
    getIdTokenClaims,
    handleUnauthorized,
    setIsLoad,
    isLoading,
  ]);

  // Fetch channel data on component mount
  useEffect(() => {
    const fetchChannel = async () => {
      setIsLoad(true);
      try {
        const endpoint = `${config.baseURL}/admin/v1/channel_list`;
        const getIdToken = await getIdTokenClaims();
        const idToken = getIdToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setChannelData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    };

    fetchChannel();
  }, [
    getIdTokenClaims,
    isLoading,
    handleUnauthorized,
    setIsLoad,
    setChannelData,
  ]);

  // Function to handle input change for filtering
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  // Function to clear filter input
  const clearInput = () => {
    setFilter("");
  };

  // Function to navigate to response details
  const navigateToResponseDetails = (id) => {
    navigate(`/response/${id}`);
  };

  // Function to navigate to channel details
  const navigateToChannelDetails = (id) => {
    navigate(`/channel/${id}`);
  };

  return (
    <SweetLayout>
    <div>
      <h1>Response</h1>
      <InputGroup className="mt-2" style={{ maxWidth: "20rem" }}>
        <Form.Control
          type="text"
          value={filter}
          onChange={handleInputChange}
          placeholder="Filter by query"
        />
        {filter && (
          <Button variant="outline-secondary fw-bold" onClick={clearInput}>
            X
          </Button>
        )}
      </InputGroup>
      <Select
        value={
          channelData.find((channel) => channel.value === selectedChannel) ||
          null
        }
        options={[
          ...(channelData.length > 0
            ? [{ value: "all", label: "All Channels", key: "all" }]
            : []),
          ...channelData.map((channel) => ({
            value: channel.channel,
            label: `${channel.channel} - ${channel.name}`,
            key: channel.channel,
          })),
        ]}
        onChange={(selectedOption) =>
          setSelectedChannel(selectedOption ? selectedOption.value : null)
        }
        disabled={channelData.length === 0}
        isSearchable
        placeholder={selectedChannel ? selectedChannel : "Select Channels"}
        className="mt-2"
        styles={{
          container: (provided) => ({ ...provided, width: "20rem" }),
        }}
      />

      {isLoad && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      )}

      <Table
        responsive
        striped
        bordered
        hover
        className="x-auto mt-2"
        style={{ minHeight: "106px" }}
      >
        <thead>
          <tr>
            <th>Channel ID</th>
            <th className="table-name-th">Question</th>
            <th>Channel</th>
            <th>Date</th>
            <th>Response ID</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`${item.channel_id}-${index}`}>
              <td style={{ cursor: "pointer" }}>
                <a
                  href={`/channel/${item.channel_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToChannelDetails(item.channel_id);
                  }}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-primary"
                >
                  {item.channel_id}
                </a>
              </td>
              <td>{item.question}</td>
              <td>{item.channel_name}</td>
              <td>{item.date}</td>
              <td style={{ cursor: "pointer" }}>
                <a
                  href={`/response/${item.response_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToResponseDetails(item.response_id);
                  }}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-primary"
                >
                  {item.response_id}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!data.length && (
        <div
          className="border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          No data found
        </div>
      )}
    </div>
    </SweetLayout>
  );
};

export default ResponseList;
