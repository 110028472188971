import Spinner from "react-bootstrap/Spinner";
import "./index.css";

function Loader({ children }) {
  return (
    <div className="table-spinner-contanier">
      <Spinner animation="border" />
    </div>
  );
}

export default Loader;
