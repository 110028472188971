import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../AppContext/Context";
import { api } from "../../API";
import Loader from "../loader";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";

import { config } from "../../config";
import NavigationBackButton from "../navigateback/NavigationBackButton";
import SweetLayout from "../../Layout/SweetLayout";

const CreateKsc = () => {
  const { getIdTokenClaims, isLoading } = useAuth0();
  const nav = useNavigate();
  const { setIsLoad, isLoad } = useContext(AppContext);
  const [selectedKs, setSelectedKs] = useState([]);
  const handleUnauthorized = useHandleUnauthorized();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    confidential: false,
    owner_account_id: "",
    ks: "",
  });

  const clearForm = () => {
    setSelectedKs("");
    setFormData({
      name: "",
      description: "",
      confidential: false,
      owner_account_id: null, // Reset owner_account_id to null
    });
  };

  const [ownerOptions, setOwnerOptions] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [ks, setKss] = useState([]);

  useEffect(() => {
    setIsLoad(loadingCount > 0);
  }, [loadingCount, setIsLoad]);
  const handleKsChange = (selectedOptions) => {
    const selectedKsIds = selectedOptions.map((option) => option.value); // Extracting selected values and storing in an array
    setSelectedKs(selectedKsIds); // Update selectedKs state
    setFormData({
      ...formData,
      ks: selectedKsIds, // Update formData with the array of selected KS IDs
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setLoadingCount((prevCount) => prevCount + 1);
        const endpoint = `${config.baseURL}/admin/v1/ks`;
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          const response = await api.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setKss(
            response.data.map((ks) => ({
              value: ks.ks_id,
              label: `${ks.name} (${ks.ks_id})`,
            }))
          );
        } catch (error) {
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setLoadingCount((prevCount) => prevCount - 1);
        }
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims, handleUnauthorized]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setLoadingCount((prevCount) => prevCount + 1);
        const endpoint = `${config.baseURL}/admin/v1/account`;
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          const response = await api.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          setOwnerOptions(
            response.data.map((channel) => ({
              value: channel.account_id,
              label: `${channel.name} (${channel.account_id})`,
            }))
          );
        } catch (error) {
          console.error("API request error:", error.message);
        } finally {
          setLoadingCount((prevCount) => prevCount - 1);
        }
      }
    };
    fetchData();
  }, [isLoading, getIdTokenClaims]);

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      owner_account_id: selectedOption ? selectedOption.value : "",
    });
  };
  // const handleKsChange = (selectedOption) => {
  //   setFormData({
  //     ...formData,
  //     ks: selectedOption ? selectedOption.value : "",
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const payload = {
    //   name: formData.name,
    //   description: formData.description,
    //   confidential: formData.confidential,
    //   owner_account_id: formData.owner_account_id,
    // };

    const payload = {
      name: formData.name,
      description: formData.description,
      confidential: formData.confidential,
      owner_account_id: formData.owner_account_id,
      ks_ids: selectedKs,
    };

    setIsLoad(true);
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await api.post("/admin/v1/ksc", payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast.success("KSC created successfully!");
      setTimeout(() => {
        nav(`/ksc-details/${response?.data?.ksc_id}`);
      }, 3000);
      // nav("/ksc");
      clearForm();
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      console.error("Error submitting form data:", error);
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <SweetLayout>
    <div>
      {isLoad && (
        <div
          style={{
            position: "absolute",
            left: "60%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "100%",
            top: "60px",
            // zIndex: 100,
            // height: "50%",
          }}
        >
          <Loader />
        </div>
      )}
      <ToastContainer />
      <NavigationBackButton />
      <div className="">
        <h1>Create Knowledge Source Configuration</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mt-2">
            <Form.Group controlId="formName">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-2">
            <Form.Group controlId="formDescription">
              <Form.Label className="fw-bold mt-1">Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mt-2">
            <Form.Group controlId="formks">
              <Form.Label className="fw-bold mt-1">KSs</Form.Label>
              <Select
                id="ks"
                value={ks.filter((option) => selectedKs.includes(option.value))} // Setting value based on selectedKs array
                onChange={handleKsChange}
                options={ks}
                isMulti={true}
                placeholder="Select KS ID"
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-2">
            <Form.Group controlId="formOwnerAccountId">
              <Form.Label className="fw-bold mt-1">
                Owner Account ID:
              </Form.Label>
              <Select
                id="channels"
                value={
                  formData.owner_account_id
                    ? ownerOptions.find(
                        (option) => option.value === formData.owner_account_id
                      )
                    : null
                }
                onChange={handleChange}
                options={ownerOptions}
                placeholder="Select Owner Account ID"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mt-2">
            <Form.Group
              controlId="formConfidential"
              className="d-flex justify-content-start gap-1 align-items-center"
            >
              <Form.Label className="fw-bold mt-2">Confidential</Form.Label>
              <Form.Check
                type="checkbox"
                // label="Check if confidential"
                name="confidential"
                checked={formData.confidential}
                onChange={(e) =>
                  setFormData({ ...formData, confidential: e.target.checked })
                }
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex gap-2 align-items-center mt-3">
          <div>
            <Button variant="primary" type="submit">
              Create KSC
            </Button>
          </div>
          <div>
            <Button variant="danger" onClick={clearForm}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
    </SweetLayout>
  );
};

export default CreateKsc;
