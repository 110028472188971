import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import ActivitiesChart from "./partials/ActivitiesChart";
import ResponseCountChart from "./partials/ResponseCountChart";
import UserFeedbackChart from "./partials/UserFeedbackChart";
import ReportingFilter from "./partials/ReportingFilter";
import Select from "react-select";
import SweetLayout from "../../Layout/SweetLayout";

function Reporting() {
    const { user, getIdTokenClaims } = useAuth0();
    const [channels, setChannels] = useState([]);
    const [channel_list, setChannelList] = useState([]);
    const [filterChannels, setFilterChannels] = useState("");
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [range, setRange] = useState(30);
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [loadingAccounts, setLoadingAccounts] = useState(false);

    const hasFetchedAccounts = useRef(false); // Track if accounts have been fetched

    const getReports = async (type, lookback, series, channel, startDate, endDate) => {
       try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
            },
        };

        let parameters = "";

        if(type){
            parameters += `data_type=${encodeURIComponent(type)}`;
        }
        if(lookback && !startDate && !endDate){
            parameters += `&lookback=${encodeURIComponent(lookback)}`;
        }
        if(series){
            parameters += `&series_type=${encodeURIComponent(series)}`;
        }
        if(channel){
            console.log("in function- ", channel);
            parameters += `&channel_id_list=${encodeURIComponent(channel)}`;
        }

        if(startDate){
            parameters += `&start_date=${encodeURIComponent(startDate)}`;
        }
        if(endDate){
            parameters += `&end_date=${encodeURIComponent(endDate)}`;
        }
        if(selectedAccount){
            if(selectedAccount.value !== "all"){
              parameters += `&account_id=${encodeURIComponent(selectedAccount.value)}`;
            }
        }

        const path = `/usage?${parameters}`;
        const url = `${config.baseURL}/admin/v1${path}`;
        const response = await fetch(url, options);
        const data = await response.json();
        return data;
       } catch (error) {
        console.error(error);
       }
    }

    const fetchAccounts = async () => {
      try {
        setLoadingAccounts(true);
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
         const options = {
           method: 'GET',
           headers: {
             Authorization: `Bearer ${idToken}`,
             'Content-Type': 'application/json'
           },
         };
        const path = `/account`;
        const url = `${config.baseURL}/admin/v1${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setAccounts(result);
        setLoadingAccounts(false);
        setSelectedAccount({ value: "all", label: "All" });
        hasFetchedAccounts.current = true; // Mark that accounts have been fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingAccounts(false);
      }
    };

    const fetchChannels = async () => {
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
           let path = `/channel`;
           if(selectedAccount.value !== "all"){
              path = `/channel?account_id=${selectedAccount.value}`;
           }
          
          const url = `${config.baseURL}/admin/v1${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
           setChannelList(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

    useEffect(() => {
      if (selectedAccount !== null && user) {
        fetchChannels();
      }
    }, [user, selectedAccount]);

    useEffect(() => {
      if (user && !hasFetchedAccounts.current && !loadingAccounts) {
        console.log("fetching accounts");
        fetchAccounts();
        console.log(user);
      }
    }, [user, loadingAccounts]);

    const handleChannelChange = (selectedOptions) => {
        setChannels(selectedOptions);
        const options = selectedOptions.map((channel) => channel.value).join(",");
        setFilterChannels(options);
    };

    const handleAccountChange = (selectedOption) => {
        setSelectedAccount(selectedOption);
        console.log(selectedOption);
    };

  return (
    <SweetLayout>

          <div className="reporting-page">
            <Row>
              <Col>
              <div className="response-filters reporting-filters">
              <div className="filters-input">
              <Select
                id="select-account"
                onChange={handleAccountChange}
                value={selectedAccount}
                options={[
                  { value: "all", label: "All" }, // Default option for "All"
                  ...accounts.map((account, index) => ({
                    value: account.account_id,
                    label: account.account_id + " - " + account.owner_user_id,
                  })),
                ]}
                isSearchable
                placeholder="Select Account"
              />

                </div>
                <div className="filters-input">
                <Select
                        id="select-channels"
                        onChange={handleChannelChange}
                        value={channels}
                        options={channel_list.map((channel, index) => ({
                        value: channel.channel,
                        label: (channel.channel +" - " +channel.name),
                        }))}
                        isSearchable
                        placeholder="Select Channels"
                        isMulti
                    />
                </div>
                <div>
                <ReportingFilter 
                    getReports={getReports}
                    range={range}
                    setRange={setRange}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    start_date={start_date}
                    end_date={end_date}
                    loading={loading}
                    />
                </div>
                
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ResponseCountChart 
                    getReports={getReports}
                    channel={filterChannels}
                    start_date={start_date}
                    end_date={end_date}
                    range={range}
                    setLoading={setLoading}
                    selectedAccount={selectedAccount}
                />
              </Col>
            </Row>
            <Row>
                <Col>
                    <ActivitiesChart 
                        getReports={getReports}
                        channel={filterChannels}
                        start_date={start_date}
                        end_date={end_date}
                        range={range}
                        setLoading={setLoading}
                        selectedAccount={selectedAccount}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <UserFeedbackChart 
                        getReports={getReports}
                        channel={filterChannels}
                        start_date={start_date}
                        end_date={end_date}
                        range={range}
                        setLoading={setLoading}
                        selectedAccount={selectedAccount}
                    />
                </Col>
            </Row>
          </div>
    </SweetLayout>
  );
}

export default Reporting;
