import React from "react";

const NoDataFound = ({ style }) => {
  return (
    <div>
      <div
        className="border border-dark d-flex align-items-center justify-content-center fw-bold"
        style={style}
      >
        No data found
      </div>
    </div>
  );
};

export default NoDataFound;
