import React from "react";
import { useNavigate } from "react-router-dom";

const NavigationBackButton = () => {
  const nav = useNavigate();
  return (
    <div
      onClick={() => nav(-1)}
      className="fe-5 fw-bold"
      style={{ cursor: "pointer" }}
    >
      <span className="bi bi-chevron-left"></span> Back
    </div>
  );
};

export default NavigationBackButton;
