import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Table, Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import Form from "react-bootstrap/Form";
import debounce from "lodash/debounce";
import Button from "react-bootstrap/Button";
import Loader from "../loader";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import CustomPagination from "../../utils/pagination/CustomPagination";
import NoDataFound from "../errormessage/NoDataFound";
import FetchDataMessage from "../fetchdatamessage/FetchDataMessage";
import { set } from "lodash";

const Account = () => {
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { setIsLoad, isLoad} =
    useContext(AppContext);
    const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const nav = useNavigate();
  const handleUnauthorized = useHandleUnauthorized();
  const [filterError, setFilterError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("currentPage")) || 1
  );
  const recordsPerPage = 20;

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoad(true);
        if (data.length === 0) {
          setCurrentPage(1);
        }

        const endpoint =
          typeof filter === "string"
            ? `${config.baseURL}/admin/v1/account${
                filter ? `?query=${encodeURIComponent(filter)}` : ""
              }`
            : `${config.baseURL}/admin/v1/account/${encodeURIComponent(
                filter
              )}`;
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          console.log("Bearer Token:", idToken);
          if (typeof filter === "number") {
            setData([response.data]);
          } else {
            setData(response.data);
          }
          if (response.data.account_id === null) {
            setFilterError(true);
          } else {
            setFilterError(false);
          }
        } catch (error) {
          console.error("API request error:", error.message);
          setData([]);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };

    const debouncedFetchData = debounce(fetchData, 2000);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [isLoading, filter, handleUnauthorized]);

  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  useEffect(() => {
    // Reset data and pagination when filter is cleared
    if (filter === "") {
      setData([]);
      setCurrentPage(1);
    }
  }, [filter]);

  const renderAccountId = (accountId) => (
    <span
      onClick={() => {
        sessionStorage.setItem("currentPage", currentPage);
        navigateToAccountDetails(accountId);
      }}
      style={{ color: "blue", cursor: "pointer" }}
    >
      <a
        href={`/account/${accountId}`}
        onClick={(e) => e.preventDefault()}
        style={{ textDecoration: "none" }}
      >
        <div className="p-1 text-center">{accountId}</div>
      </a>
    </span>
  );

  const formatDate = (dateString) => {
    const isValidDate = !isNaN(Date.parse(dateString));

    if (!isValidDate) {
      return <div className="text-center">-</div>;
    }
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };

  const navigateToAccountDetails = (accountId) => {
    nav(`/account/${accountId}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    sessionStorage.setItem("currentPage", pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return data.slice(startIndex, startIndex + recordsPerPage);
  };

  const totalPages = Math.ceil(data.length / recordsPerPage);

  return (
    <div>
      {showErrorMessage ? (
        <div
          className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          You are not authorized to use this app. Please contact your ept AI
          administrator if you believe you should have access.
        </div>
      ) : (
        <div>
          <Row className="mb-1">
            <Col xs={12} sm={12} md={8} className="mt-1">
              <div className="d-flex gap-3 align-items-center">
                <h1>Accounts</h1>
                <Form.Control
                  size="lg"
                  type="search"
                  style={{ maxWidth: "200px" }}
                  defaultValue={filter}
                  placeholder="Search"
                  onChange={(e) => {
                    setIsLoad(true);
                    const input = e.target.value;
                    const numericInput = parseFloat(input);
                    setCurrentPage(1);
                    setFilter(isNaN(numericInput) ? input : numericInput);
                  }}
                  value={filter || ""} // Ensure the value is controlled by the filter state
                />
              </div>
            </Col>
            {showEditButton && (
              <Col xs={12} sm={12} md={4} className="mt-1">
                <div className="d-flex justify-content-md-end justify-content-sm-start">
                  <Button variant="dark" onClick={() => nav("/create-account")}>
                    Create New Account
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          <div style={{ overflowX: "auto", position: "relative" }}>
            {isLoad && (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}

            <Table
              responsive
              striped
              bordered
              hover
              className="x-auto hide-table"
              style={{ minHeight: "106px" }}
            >
              <thead>
                <tr>
                  <th>Account ID</th>
                  <th>Name</th>
                  <th>Stripe Customer ID</th>
                  <th>Created At</th>
                  <th>Owner User ID</th>
                  <th>Subscription ID</th>
                  <th>Plan ID</th>
                  <th>End Date</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {getPaginatedData().map((item, index) => (
                  <tr key={index}>
                    <td>{renderAccountId(item.account_id)}</td>
                    <td>{item.name}</td>
                    <td>{item.stripe_customer_id}</td>
                    <td>{formatDate(item.created_at)}</td>
                    <td>{item.owner_user_id}</td>
                    <td>
                      {item.subscription_id !== null
                        ? item.subscription_id
                        : "null"}
                    </td>
                    <td>{item.plan_id !== null ? item.plan_id : "null"}</td>
                    <td>{item.end_date !== null ? item.end_date : "null"}</td>
                    <td>{formatDate(item.updated_at)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {(!data || data.length === 0) && filter === "" && (
              <FetchDataMessage style={{ minHeight: "300px" }} />
            )}
            {!isLoad && (
              <>
                {filterError && <NoDataFound style={{ minHeight: "300px" }} />}
              </>
            )}

            {!filterError && (
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;

