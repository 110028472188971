import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Table, Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import NestedObjectModal from "../modals/NestedObjectModal";
import debounce from "lodash/debounce";
import Loader from "../loader";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import CustomPagination from "../../utils/pagination/CustomPagination";
import SweetLayout from "../../Layout/SweetLayout";

const Ks = () => {
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalTitle, setModalTitle] = useState("Nested Object Details");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const nav = useNavigate();
  const {
    setIsLoad,
    isLoad,
    filter,
    setFilter,
    selectedType,
    setSelectedType,
  } = useContext(AppContext);
  const [data, setData] = useState([]);
  const handleUnauthorized = useHandleUnauthorized();
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const [filterError, setFilterError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("currentPage")) || 1
  );
  const recordsPerPage = 20;

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoad(true);
        if (data.length === 0) {
          setCurrentPage(1);
        }
        let endpoint = `${config.baseURL}/admin/v1/ks`;

        if (filter) {
          const numericFilter = parseInt(filter);
          if (!isNaN(numericFilter)) {
            endpoint += `/${numericFilter}`;
          } else {
            endpoint += `?query=${encodeURIComponent(filter)}`;
          }
        }

        if (selectedType) {
          if (filter) {
            endpoint += `?type=${selectedType}`;
          } else {
            endpoint += `?type=${selectedType}`;
          }
        }
        try {
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          });
          const responseData = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setData(responseData);
          setFilterError(false);
        } catch (error) {
          if (error?.request?.status === 500) {
            setFilterError(true);
          } else {
            setFilterError(false);
          }
          console.error("API request error:", error.message);
          handleUnauthorized(error);
        } finally {
          setIsLoad(false);
        }
      }
    };

    const debouncedFetchData = debounce(fetchData, 2000);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [
    isLoading,
    filter,
    selectedType,
    getIdTokenClaims,
    handleUnauthorized,
    setIsLoad,
  ]);

  const types = [
    "website",
    "community_forum",
    "aws_s3",
    "zendesk_knowledge_base",
    "github",
    "contributed_knowledge",
  ];

  const handleNestedObjectClick = (record, key) => {
    const nestedData = record[key];
    setModalData(nestedData);
    setModalTitle(`${key}`);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  const columns = [
    {
      title: "KSs ID",
      dataIndex: "ks_id",
      key: "ks_id",
      render: (text, record) => (
        <span
          onClick={() => nav(`/ks/${record.ks_id}`)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          <a
            href={`/ks/${record.ks_id}`}
            onClick={(e) => e.preventDefault()}
            style={{ textDecoration: "none" }}
          >
            <div className="p-1 text-center">{text}</div>
          </a>
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Confidential",
      dataIndex: "confidential",
      key: "confidential",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text
          ? "true"
          : "false",
    },
    {
      title: "Source Details",
      dataIndex: "source_details",
      key: "source_details",
      render: (text, record) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleNestedObjectClick(record, "source_details")}
          >
            View Details
          </span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const isValidDate = !isNaN(Date.parse(created_at));

        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(created_at);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        const isValidDate = !isNaN(Date.parse(updated_at));

        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(updated_at);

        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
    {
      title: "Owner Account ID",
      dataIndex: "owner_account_id",
      key: "owner_account_id",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text !== null
          ? text
          : "N/A",
    },
    {
      title: "Stats",
      dataIndex: "stats",
      key: "stats",
      render: (text, record) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleNestedObjectClick(record, "stats")}
          >
            View Details
          </span>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Ept Config",
      dataIndex: "ept_config",
      key: "ept_config",
      render: (text, record) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleNestedObjectClick(record, "ept_config")}
          >
            View Details
          </span>
        );
      },
    },
  ];

  const totalPages = Math.ceil(data.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    sessionStorage.setItem("currentPage", pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return data.slice(startIndex, startIndex + recordsPerPage);
  };

  const formatModalTitle = (title) => {
    return title.replace(/_/g, " ");
  };

  return (
    <SweetLayout>
    <div>
      {showErrorMessage ? (
        <div
          className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          You are not authorized to use this app. Please contact your ept AI
          administrator if you believe you should have access.
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <Container fluid>
            <Row>
              <Col>
                <Row className="align-items-center">
                  <Col sm="12" md="12" lg="8">
                    <span className="d-flex flex-column flex-sm-row gap-2 mt-1">
                      <span className="d-flex gap-2">
                        <h1>KSs</h1>
                        <Form.Control
                          size="lg"
                          type="search"
                          style={{ maxWidth: "200px" }}
                          placeholder="Search"
                          defaultValue={filter}
                          value={filter || ''}
                          onChange={(e) => {
                            setIsLoad(true);
                            const input = e.target.value;
                            const numericInput = parseFloat(input);
                            setCurrentPage(1);
                            setFilter(
                              isNaN(numericInput) ? input : numericInput
                            );
                          }}
                        />
                      </span>
                      <Form.Select
                        aria-label="Select type"
                        value={selectedType}
                        onChange={(e) => {
                          setIsLoad(true);
                          setSelectedType(e.target.value);
                        }}
                        style={{ maxWidth: "200px" }}
                      >
                        <option value="">Search with type</option>
                        {types.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </span>
                  </Col>
                  {showEditButton && (
                    <Col sm="12" md="12" lg="4">
                      <div className="d-flex justify-content-lg-end justify-content-md-start mt-1">
                        <Button
                          variant="dark"
                          onClick={() => nav("/create-ks")}
                        >
                          Create New KS
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
                <div
                  className="mt-1"
                  style={{
                    overflowX: "auto",
                    position: "relative",
                  }}
                >
                  {isLoad && (
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        width: "100%",
                      }}
                    >
                      <Loader />
                    </div>
                  )}

                  {filterError ? (
                    <>
                      <hr />
                      <div
                        className="mt-2 border border-dark d-flex align-items-center justify-content-center fw-bold"
                        style={{ minHeight: "300px" }}
                      >
                        No data found
                      </div>
                    </>
                  ) : (
                    <>
                      <Table
                        responsive
                        striped
                        bordered
                        hover
                        className="x-auto"
                      >
                        <thead>
                          <tr>
                            {columns.map((column) => (
                              <th key={column.key}>{column.title}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {getPaginatedData().map((record) => (
                            <tr key={record?.ks_id}>
                              {columns.map((column) => (
                                <td key={column.key}>
                                  {column.render
                                    ? column.render(
                                        record[column.dataIndex],
                                        record
                                      )
                                    : record[column.dataIndex]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {!data.length && (
                        <div
                          className=" border border-dark d-flex align-items-center justify-content-center fw-bold"
                          style={{ minHeight: "300px" }}
                        >
                          No data found
                        </div>
                      )}
                      <CustomPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>

          <NestedObjectModal
            title={formatModalTitle(modalTitle)}
            visible={modalVisible}
            data={modalData}
            onClose={closeModal}
          />
        </div>
      )}
    </div>
    </SweetLayout>
  );
};

export default Ks;
