import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { clearLocalStorageItems } from './common/Functions';
import { useAuth0 } from "@auth0/auth0-react";
import { is } from "date-fns/locale";

function SideBarNav(props) {
    const { logout, user } = useAuth0();
    const location = useLocation();
     const [isAdmin, setIsAdmin] = useState(false);

    const handleNavToggle = (e) => {
        e.preventDefault();
        props.setIsSidebarActive((prev) => !prev);
      };

    useEffect(() => {
        if (user) {
            const roles = user?.ept_roles;
            if (roles && roles.includes('Admin')) {
                setIsAdmin(true);
            }
        }
      }, [user]);
      
  return (
    
    <div className="sidebar-nav">
        <div className="sidebar-inner">
            <a href="#" className="nav-toggle-btn" onClick={(e)=> handleNavToggle(e)}></a>
            <Link to="/" className="sidebar-logo">
                <span className="sidebar-logo-img"></span>
            </Link>
            
                <div className="nav-scroll">
                <div className="upper-nav">
                        <div className="side-nav-heading">Accounts</div>
                        {isAdmin && (
                            <>
                        <Link to="/" className={`${location.pathname === '/' ? 'active' : ''}`}>
                        <i className="fa-solid fa-address-card" style={{marginRight:'10px', verticalAlign:'5px'}}></i><span>Accounts</span>
                        </Link>
                        <Link to="/company" className={`companies-btn ${location.pathname === '/company' ? 'active' : ''}`}>
                        <i className="fa-solid fa-address-card" style={{marginRight:'10px', verticalAlign:'5px'}}></i><span>Companies</span>
                        </Link>
                        </>
                        )}
                    </div>
                
                    {isAdmin && (
                        
                        
                        <div className="upper-nav">
                            <div className="side-nav-heading">Channels & Knowledge</div>
                            <Link to="/channel" className={`channel-btn ${location.pathname === '/channel' ? 'active' : ''}`}>
                                <span>Channel Management</span>
                            </Link>
                            <Link to="/ks" className={`ks-btn ${location.pathname === '/ks' || location.pathname.startsWith('/ks/') ? 'active' : ''}`}>
                                <span>Knowledge Sources</span>
                            </Link>
                            <Link to="/ksc" className={` ksc-btn ${location.pathname.startsWith('/ksc') ? 'active' : ''}`}>
                                <span>Knowledge Source Configurations</span>
                            </Link>
                        </div>

                        
                    )}
                    <div className="upper-nav">
                        <div className="side-nav-heading">AI Performance Management</div>
                        {isAdmin && (
                            <>
                        <Link to="/response" className={`response-btn ${location.pathname === '/response' ? 'active' : ''}`}>
                            <span>Responses</span>
                        </Link>
                        
                        <Link to="/reporting" className={`analysis-btn ${location.pathname === '/analysis' ? 'active' : ''}`}>
                            <span>Reporting</span>
                        </Link>

                        <Link to="/experiments" className={`analysis-btn ${location.pathname === '/experiments' ? 'active' : ''}`}>
                            <span>Experiments</span>
                        </Link>
                        </>
                        )}
                    </div>
                        
                    
                    
                    <div className="upper-nav">
                        <div className="side-nav-heading">User Management</div>
                        {isAdmin && (
                        <>
                        <Link to="/users" className={`user-btn ${location.pathname === '/users' ? 'active' : ''}`}>
                            <span>Users</span>
                        </Link>
                        </>
                        )}
                        <a className="logout-btn" href="#" onClick={() => {
                      // check if itme exist in local storage
                      clearLocalStorageItems();
                      localStorage.removeItem('redirectPath');
                    logout({ logoutParams: { returnTo: window.location.origin } })
                    }}><span>Logout</span></a> 
                    </div>
                    
            </div>
            
            {/* <div className="lower-nav">
                <div className="nav-item">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </div>  
            </div> */}
        </div>
    </div>
    
    );
}

export default SideBarNav;