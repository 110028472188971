import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Accordion from "react-bootstrap/Accordion";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import NestedObjectModal from "../modals/NestedObjectModal";
import Loader from "../loader";
import Select from "react-select";
import { api } from "../../API";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";

import { useNavigate } from "react-router-dom";
import Ksc from "../ksc/Ksc";
import NoDataFound from "../errormessage/NoDataFound";
import SweetLayout from "../../Layout/SweetLayout";

const Account = () => {
  const nav = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const [editModeUser, setEditModeUser] = useState(false);
  const [editableUser, setEditableUser] = useState(false);
  const { setIsLoad, isLoad } = useContext(AppContext);
  const [selectedTitle, setSelectedTitle] = useState("Description");
  const [isEditRoleDone, setIsEditRoleDone] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState([]);
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState();
  const [KscId, setKscId] = useState([]);
  const { id } = useParams();
  const [editableFields, setEditableFields] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);
  const handleUnauthorized = useHandleUnauthorized();

  const navigateToUserTab = (email) => {
    nav(`/users?email=${email}`);
  };

  const renderEmail = (email) => (
    <span
      onClick={() => navigateToUserTab(email)}
      style={{ color: "blue", cursor: "pointer" }}
    >
      <a
        href={`/users?email=${email}`}
        onClick={(e) => e.preventDefault()}
        style={{ textDecoration: "none" }}
      >
        <div className="">{email}</div>
      </a>
    </span>
  );

  useEffect(() => {
    fetchChannelData();
    fetchData();
    fetchDataKsc();
    // fetchUsersData();
  }, [isLoading, getIdTokenClaims, isEditRoleDone, handleUnauthorized, id]);
  const fetchData = async () => {
    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/account/${id}`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    }
  };
  const fetchChannelData = async () => {
    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/channels`;
      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setChannels(
          response.data.map((channel) => ({
            value: channel.channel,
            label: `${channel.name} (${channel.channel})`,
          }))
        );
      } catch (error) {
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    }
  };

  const fetchDataKsc = async () => {
    if (!isLoading) {
      setIsLoad(true);
      const body = { account_id: id };
      const endpoint = `${config.baseURL}/admin/v1/ksc`;

      try {
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          params: body,
        });

        setKscId(response?.data);
      } catch (error) {
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    }
  };
  // const fetchUsersData = async () => {
  //   if (!isLoading) {
  //     setIsLoad(true);

  //     const endpoint = `${config.baseURL}/admin/v1/users?account_id=1`;
  //     try {
  //       const getidToken = await getIdTokenClaims();
  //       const idToken = getidToken.__raw;
  //       const response = await axios.get(endpoint, {
  //         headers: {
  //           Authorization: `Bearer ${idToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       setUsers(response.data);
  //     } catch (error) {
  //       console.error("API request error:", error.message);
  //     } finally {
  //       setIsLoad(false);
  //     }
  //   }
  // };
  const column = [
    {
      title: "Name",
    },
    { title: "Email" },
    { title: "Last Login" },
    { title: "Roles" },
  ];

  const columns = [
    {
      title: "Account ID",
      dataIndex: "account_id",
      key: "account_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Stripe Customer ID",
      dataIndex: "stripe_customer_id",
      key: "stripe_customer_id",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const isValidDate = !isNaN(Date.parse(created_at));

        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(created_at);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
    {
      title: "Owner User ID",
      dataIndex: "owner_user_id",
      key: "owner_user_id",
    },
    {
      title: "Subscription ID",
      dataIndex: "subscription_id",
      key: "subscription_id",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text !== null
          ? text
          : "N/A",
    },
    {
      title: "Plan ID",
      dataIndex: "plan_id",
      key: "plan_id",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text !== null
          ? text
          : "N/A",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text, record) =>
        typeof text === "object"
          ? JSON.stringify(text)
          : text !== null
          ? text
          : "N/A",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        const isValidDate = !isNaN(Date.parse(updated_at));

        if (!isValidDate) {
          return <div className="text-center">-</div>;
        }
        const date = new Date(updated_at);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return date.toLocaleString(undefined, options);
      },
    },
    {
      title: "Forums",
      dataIndex: "forums",
      key: "forums",
      render: (text) => {
        if (
          Array.isArray(text) ||
          (typeof text === "object" && text !== null)
        ) {
          return (
            <span
              variant="primary"
              onClick={() => handleViewObject(text, "Forums")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              View Details
            </span>
          );
        }
        return text;
      },
    },
    {
      title: "Forum IDs",
      dataIndex: "forum_ids",
      key: "forum_ids",
      render: (text) => {
        if (
          Array.isArray(text) ||
          (typeof text === "object" && text !== null)
        ) {
          return (
            <span
              variant="primary"
              onClick={() => handleViewObject(text, "Forum IDs")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              View Details
            </span>
          );
        }
        return text;
      },
    },
    {
      title: "Data Sources",
      dataIndex: "data_sources",
      key: "data_sources",
      render: (text) => {
        if (
          Array.isArray(text) ||
          (typeof text === "object" && text !== null)
        ) {
          return (
            <span
              variant="primary"
              onClick={() => handleViewObject(text, "Data Sources")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              View Details
            </span>
          );
        }
        return text;
      },
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (text) => {
        if (
          Array.isArray(text) ||
          (typeof text === "object" && text !== null)
        ) {
          return (
            <span
              variant="primary"
              onClick={() => handleViewObject(text, "Users")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              View Details
            </span>
          );
        }
        return text;
      },
    },
  ];


  const dataSource = data ? [data] : [];

  const handleViewObject = (object, title) => {
    setSelectedObject(object);
    setSelectedTitle(title);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const [updatedForumIDs, setUpdatedForumIDs] = useState();
  const handleOptionChange = (selectedOption) => {
    const channelId = selectedOption.value;
    if (!data.forum_ids.includes(channelId)) {
      const updatedForumIds = [...data.forum_ids, channelId];
      setUpdatedForumIDs(updatedForumIds);
    }
  };

  const handleKsChange = (id) => {
    nav(`/ks/${id}`);
  };
  const handleKscChange = (id) => {
    nav(`/ksc/${id}`);
  };
  const handleEditChannel = () => {
    setEditableFields(true);
    setEditMode(true);
  };
  const handleEditUser = () => {
    setEditModeUser(true);
    setEditableUser(true);
  };
  const accountID = parseInt(id);
  const handleSaveUser = async () => {
    setEditModeUser(false);
    setEditableUser(false);
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = {
        email: email,
        account_id: accountID,
        groups: selectedRoleValues,
      };
      await api.post(`${config.baseURL}/admin/v1/user `, payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setIsEditRoleDone(!isEditRoleDone);
      setIsLoad(false);
      setEmail("");
      setSelectedRole([]);
    } catch (error) {
      console.error("Error:", error);
      setIsLoad(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const options = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
  ];

  const handleRoleChange = (selectedOptions) => {
    setSelectedRole(selectedOptions);
  };

  // Function to filter out selected options from the options list
  const filteredOptions = options.filter(
    (option) =>
      !selectedRole.find((selected) => selected.value === option.value)
  );
  const handleSaveChannel = async () => {
    setEditableFields(false);
    setEditMode(false);

    if (!isLoading) {
      setIsLoad(true);
      try {
        const endpoint = `${config.baseURL}/admin/v1/account/${id}`;
        const idToken = (await getIdTokenClaims()).__raw;
        const payload = { channel_ids: updatedForumIDs };

        await axios.put(endpoint, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const updatedData = response.data;

        setData(updatedData);
      } catch (error) {
        console.error("Error Saving channel:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };

  const ksOptions = () => {
    return data?.data_sources?.map((value, index) => ({
      value: value.embedding_source_id,
      label: value.embedding_source_id,
    }));
  };
  const channelOptions = () => {
    return data?.forum_ids?.map((value, index) => ({ value, label: value }));
  };

  const handleClick = (id) => {
    nav(`/channel/${id}`);
  };

  const equalOfAccountOwner =
    data &&
    data.forums &&
    data.forums.some((forum) => forum.owner_account_id === data.account_id);

  const handleDeleteIds = async (forumId) => {
    if (!isLoading) {
      setIsLoad(true);
      try {
        const endpoint = `${config.baseURL}/admin/v1/account/${id}`;
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;

        const updatedForums = data.forums.filter(
          (forum) => forum.forum_id !== forumId
        );

        const updatedForumIds = updatedForums.map((forum) => forum.forum_id);

        setData({
          ...data,
          forums: updatedForums,
        });

        const payload = { forum_ids: updatedForumIds };

        await axios.put(endpoint, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
        console.error("Error deleting forum ID:", error.message);
      } finally {
        setIsLoad(false);
      }
    }
  };
  const selectedRoleValues = selectedRole?.map((option) => option.value);
  return (
    <SweetLayout>
    <div>
      <div style={{ overflowX: "auto" }}>
        <div
          onClick={() => nav(-1)}
          className="fe-5 fw-bold"
          style={{ cursor: "pointer" }}
        >
          <span className="bi bi-chevron-left"></span> Back
        </div>
        <h1>Account</h1>
        <div className="w-100">
          {isLoad && (
            <div
              style={{
                position: "absolute",
                // top: "150px",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // height: "300px",
                // border: "1px solid red",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          )}
          <Table responsive striped bordered hover className="x-auto">
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSource.map((record, index) => (
                <tr key={index}>
                  {columns.map((column, index) => (
                    <td key={index}>
                      {column.render
                        ? column.render(record[column.dataIndex], record)
                        : record[column.dataIndex]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          <Accordion className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4>Users</h4>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  style={{
                    position: "relative",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {editModeUser && (
                    <div
                      className="mt-3 mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className="d-flex ms-5 align-items-center">
                        <label>
                          <strong className="me-1">Email:</strong>
                        </label>
                        <input
                          style={{
                            width: "20rem",
                            border: "1px solid #ddd",
                            padding: "6px",
                            outline: "none",
                            borderRadius: "4px",
                          }}
                          onChange={handleEmailChange}
                          type="text"
                          value={email}
                          required
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="d-flex me-5 align-items-center">
                        <label>
                          {" "}
                          <strong className="me-1">Roles: </strong>
                        </label>
                        <div style={{ width: "20rem" }}>
                          <Select
                            styles={{ width: "20rem" }}
                            value={selectedRole}
                            onChange={handleRoleChange}
                            placeholder="Select Role"
                            isMulti
                            required
                            options={filteredOptions}
                            formatOptionLabel={({ label }) => label}
                            formatValueLabel={({ label }) =>
                              `Selected: ${label}`
                            }
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showEditButton && !editableUser && (
                    <i
                      className="fa fa-plus-square-o"
                      onClick={handleEditUser}
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        position: "absolute",
                        right: "0",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    ></i>
                  )}
                  {editableUser && (
                    <i
                      className="fa fa-save"
                      onClick={handleSaveUser}
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        position: "absolute",
                        right: "0",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    ></i>
                  )}
                </div>
                {data?.users && data.users.length !== 0 ? (
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="x-auto hide-table"
                    style={{ minHeight: "106px" }}
                  >
                    <thead>
                      <tr>
                        {column.map((column, index) => (
                          <th key={index}>{column.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.users.map((user, index) => (
                        <tr key={index}>
                          <td>
                            {user.name ? (
                              user.name
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                          <td>
                            {renderEmail(
                              user.email ? (
                                user.email
                              ) : (
                                <div
                                  aria-disabled
                                  className="text-center text-black"
                                >
                                  -
                                </div>
                              )
                            )}
                          </td>
                          <td>
                            {user.last_login ? (
                              user.last_login
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                          <td>
                            {user?.groups?.length > 0 ? (
                              <ul>
                                {user.groups.map((role, index) => (
                                  <li key={index}>{role}</li>
                                ))}
                              </ul>
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataFound style={{ minHeight: "200px" }} />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-5" eventKey="1">
              <Accordion.Header>
                {" "}
                <h4>Channels</h4>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ width: "350px" }}>
                  {editMode && (
                    <Select
                      id="ks"
                      value={selectedChannels}
                      onChange={handleOptionChange}
                      options={channels}
                      // isMulti={true}
                      placeholder="Select Channel ID"
                    />
                  )}
                  {showEditButton && !editableFields && (
                    <i
                      className="fa fa-plus-square-o"
                      onClick={handleEditChannel}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                  )}
                  {editableFields && (
                    <i
                      className="fa fa-save"
                      onClick={handleSaveChannel}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    ></i>
                  )}
                </div>

                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="x-auto hide-table"
                  style={{ minHeight: "106px" }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.forums && data.forums.length > 0 ? (
                      data.forums.map((value, index) => (
                        <tr key={index}>
                          <td>
                            {value.name ? (
                              value.name
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                          <td>
                            {value.forum_id ? (
                              <Link to={`/channel/${value.forum_id}`}>{value.forum_id}</Link>
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                          <td className="text-center">
                            {!equalOfAccountOwner ? (
                              <i
                                className="fa fa-trash"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteIds(value.forum_id)}
                              ></i>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <NoDataFound style={{ minHeight: "200px" }} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-5" eventKey="2">
              <Accordion.Header>
                <h4>KSs</h4>
              </Accordion.Header>
              <Accordion.Body>
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="x-auto hide-table"
                  style={{ minHeight: "106px" }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data_sources && data.data_sources.length > 0 ? (
                      data.data_sources.map((value, index) => (
                        <tr key={index}>
                          <td>
                            {value.description ? (
                              value.description
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                          <td
                            onClick={() =>
                              handleKsChange(value.embedding_source_id)
                            }
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            {value.embedding_source_id ? (
                              value.embedding_source_id
                            ) : (
                              <div className="text-center">-</div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">
                          <NoDataFound style={{ minHeight: "200px" }} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-5" eventKey="3">
              <Accordion.Header>
                <h4>KSCs</h4>
              </Accordion.Header>
              <Accordion.Body>
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="x-auto hide-table"
                  style={{ minHeight: "106px" }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="text-center">ID</th>
                    </tr>
                  </thead>
                  {data ? (
                    KscId.length > 0 ? (
                      <tbody>
                        {KscId.map((value, index) => (
                          <tr key={index}>
                            <td>
                              {value.name ? (
                                value.name
                              ) : (
                                <div className="text-center">-</div>
                              )}
                            </td>
                            <td className="text-center">
                              <span
                                onClick={() =>
                                  nav(`/ksc-details/${value.ksc_id}`)
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                <div className="p-1 text-center">
                                  {value.ksc_id ? (
                                    value.ksc_id
                                  ) : (
                                    <div className="text-center">-</div>
                                  )}
                                </div>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <NoDataFound style={{ minHeight: "200px" }} />
                          </td>
                        </tr>
                      </tbody>
                    )
                  ) : null}
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <NestedObjectModal
        title={selectedTitle}
        visible={modalVisible}
        data={selectedObject}
        onClose={closeModal}
      />
    </div>
    </SweetLayout>
  );
};

export default Account;
