import React from "react";
import { Container } from "react-bootstrap";
import SimpleHeader from "../components/SimpleHeader";
import MainAppWrap from "./MainAppWrap";

const SweetLayout = ({ children }) => {
  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl">{children}</Container>
      </div>
    </MainAppWrap>
  );
};

export default SweetLayout;
