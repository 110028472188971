import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Table, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import debounce from "lodash/debounce";
import Loader from "../loader";
import CustomPagination from "../../utils/pagination/CustomPagination";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import NoDataFound from "../errormessage/NoDataFound";
import FetchDataMessage from "../fetchdatamessage/FetchDataMessage";
import SweetLayout from "../../Layout/SweetLayout";

const Ksc = () => {
  const { isLoading, getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const { setIsLoad, isLoad, filter, setFilter } =
    useContext(AppContext);
    const [data, setData] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const showEditButton = user?.ept_roles?.includes("EptAdmin");
  const nav = useNavigate();
  const handleUnauthorized = useHandleUnauthorized();
  const [filterError, setFilterError] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("currentPage"), 10) || 1
  );
  const recordsPerPage = 20;

  useEffect(() => {
    if (user && user.ept_roles) {
      if (
        !user.ept_roles.includes("EptAdmin") &&
        !user.ept_roles.includes("EptViewer")
      ) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }
  }, [user, isAuthenticated, isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);
      if (data.length === 0) {
        setCurrentPage(1);
      }

      const endpoint =
        typeof filter === "string"
          ? `${config.baseURL}/admin/v1/ksc${
              filter ? `?query=${encodeURIComponent(filter)}` : ""
            }`
          : `${config.baseURL}/admin/v1/ksc/${encodeURIComponent(filter)}`;

      try {
        const { __raw: idToken } = await getIdTokenClaims();
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });

        setData(Array.isArray(response.data) ? response.data : [response.data]);
        setFilterError(false);
      } catch (error) {
        console.error("API request error:", error.message);
        setFilterError(true);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    };

    const debouncedFetchData = debounce(fetchData, 2000);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [isLoading, filter, getIdTokenClaims, setIsLoad, handleUnauthorized]);

  useEffect(() => {
    // Reset data and pagination when filter is cleared
    if (filter === "") {
      setData([]);
      setCurrentPage(1);
    }
  }, [filter]);

  const columns = [
    { title: "KSCs ID", dataIndex: "ksc_id" },
    { title: "Name", dataIndex: "name" },
    { title: "Description", dataIndex: "description" },
    {
      title: "Confidential",
      dataIndex: "confidential",
      render: (confidential) => (confidential ? "true" : "false"),
    },
    {
      title: "Owner Account ID",
      dataIndex: "owner_account_id",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => formatDate(updated_at),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (created_at) => formatDate(created_at),
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const totalPages = Math.ceil(data.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    sessionStorage.setItem("currentPage", pageNumber.toString());
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return data.slice(startIndex, startIndex + recordsPerPage);
  };

  return (
    <SweetLayout>
    <Container fluid>
      {showErrorMessage ? (
        <div
          className="border border-dark d-flex align-items-center justify-content-center fw-bold"
          style={{ minHeight: "300px" }}
        >
          You are not authorized to use this app. Please contact your ept AI
          administrator if you believe you should have access.
        </div>
      ) : (
        <Row>
          <Col>
            <div className="mb-3 align-items-center">
              <Row>
                <Col sm="12" md="6" lg="9">
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <h1 className="mt-1">KSCs</h1>
                    <Form.Control
                      size="lg"
                      type="search"
                      style={{ maxWidth: "200px" }}
                      value={filter || ""}
                      placeholder="Search"
                      onChange={(e) => {
                        setIsLoad(true);
                        const input = e.target.value;
                        setCurrentPage(1); // Reset to the first page when the filter changes
                        if (input.trim() === "") {
                          setFilter("");
                        } else {
                          const numericInput = parseFloat(input);
                          setFilter(isNaN(numericInput) ? input : numericInput);
                        }
                      }}
                    />
                  </div>
                </Col>
                {showEditButton && (
                  <Col sm="12" md="6" lg="3">
                    <div className="d-flex justify-content-md-end justify-content-sm-start align-items-center mt-1">
                      <Button variant="dark" onClick={() => nav("/create-ksc")}>
                        Create New KSC
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div
              className="mt-1"
              style={{ overflowX: "auto", position: "relative" }}
            >
              {isLoad && (
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    width: "100%",
                  }}
                >
                  <Loader />
                </div>
              )}
              {!filterError && (
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="x-auto"
                  style={{ minHeight: "106px" }}
                >
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th key={column.dataIndex}>{column.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getPaginatedData().map((record) => (
                      <tr key={record.ksc_id}>
                        {columns.map((column) => (
                          <td key={column.dataIndex}>
                            {column.dataIndex === "ksc_id" ? (
                              <span
                                onClick={() =>
                                  nav(`/ksc-details/${record.ksc_id}`)
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                {record[column.dataIndex]}
                              </span>
                            ) : column.dataIndex === "confidential" ? (
                              record[column.dataIndex] ? (
                                "true"
                              ) : (
                                "false"
                              )
                            ) : column.dataIndex === "updated_at" ||
                              column.dataIndex === "created_at" ? (
                              <span>
                                {column.render(record[column.dataIndex])}
                              </span>
                            ) : (
                              record[column.dataIndex]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {(!isLoad || isLoad) &&
                data &&
                data.length === 0 &&
                filter === "" && (
                  <FetchDataMessage style={{ minHeight: "300px" }} />
                )}
              {filterError && filter && (
                <NoDataFound style={{ minHeight: "300px" }} />
              )}

              {!filterError && (
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
    </SweetLayout>
  );
};

export default Ksc;
