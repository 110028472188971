import React, { useState } from "react";
import { Button, Table, Accordion, Dropdown } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { api } from "../../API";

const TableForVersions = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const { getIdTokenClaims } = useAuth0();
  const [ksEventIdToDelete, setKsEventIdToDelete] = useState(null);

  const handleDeleteClick = (ksEventId) => {
    setKsEventIdToDelete(ksEventId);
    setShowModal(true);
  };

  const handleDelete = async (ksEventId) => {

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const response = await api.delete(`/admin/v1/ks_event/${ksEventId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      toast.success("Data deleted successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error deleting data. Please try again.");
    }
  };

  const formattedDate = (eventDate) => {
    const date = new Date(eventDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleVectorize = async (ksEventId, e) => {
    e.preventDefault();
    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = { action: "vectorize" };
      const response = await api.put(
        `/admin/v1/ks_event/${ksEventId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      toast.success("Data vectorize successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error vectorizing data. Please try again.");
    }
  };

  const handleSave = async (ksEventId, e) => {
    e.preventDefault();

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = { action: "save" };
      const response = await api.put(
        `/admin/v1/ks_event/${ksEventId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      toast.success("Data saved successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error saving data. Please try again.");
    }
  };

  const handleIngest = async (e) => {
    e.preventDefault();

    e.target.disabled = true;


    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const response = await api.put(
        `/admin/v1/ks_ingest/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      toast.success("Data loaded successfully!");

      await new Promise((resolve) => setTimeout(resolve, 5000));

      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error loading data. Please try again.");

      e.target.disabled = false;
    }
  };

  const handleMakeActive = async (ksEventId, e) => {
    e.preventDefault();

    try {
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const payload = { action: "activate" };
      const response = await api.put(
        `/admin/v1/ks_event/${ksEventId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      toast.success("Data active successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error activating data. Please try again.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setKsEventIdToDelete();
  };
  const isProcessing =
    data && data.some((item) => item.status === "processing");
  


  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="w-100">
            {" "}
            <strong> Versions </strong>{" "}
          </Accordion.Header>{" "}
          <Accordion.Body>
            <div>
              <div className="d-flex  justify-content-between align-items-center">
                <Button
                  variant="dark"
                  onClick={(e) => handleIngest(e)}
                  disabled={isProcessing} 
                >
                  Ingest
                </Button>
              </div>

              {data && (
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="x-auto mt-1"
                >
                  <thead>
                    <tr>
                      <th rowSpan={2}>ID</th>
                      <th colSpan={2}>Ingest</th>
                      <th colSpan={2}>Vectorization</th>
                      <th colSpan={2}>Save</th>
                      <th rowSpan={2}>Actions</th>
                      <th rowSpan={2}>Status</th>
                    </tr>
                    <tr>
                      <th>File Name</th>
                      <th>Event Date</th>
                      <th>File Name</th>
                      <th>Event Date</th>
                      <th>Namespace</th>
                      <th>Event Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                          >
                            {index + 1}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                          >
                            {item?.ingest_filename || "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                            style={{ width: "10rem" }}
                          >
                            {formattedDate(item?.event_date) || "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                            colSpan={1}
                          >
                            {item?.vectorizations?.length > 0 ? "" : "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                            colSpan={1}
                          >
                            {item?.vectorizations?.length > 0 ? "" : "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                            colSpan={1}
                          >
                            {item?.vectorizations?.length > 0 ? "" : "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                            colSpan={1}
                          >
                            {item?.vectorizations?.length > 0 ? "" : "N/A"}
                          </td>
                          <td
                            className={
                              item?.vectorizations?.[0]?.saves?.[0]?.active ===
                                true && "bg-secondary"
                            }
                          >
                            <div
                              className="col d-flex justify-content-center align-items-center gap-2 h-100"
                              style={{ minHeight: "74px" }}
                            >
                              <Dropdown>
                                {/* <Dropdown.Toggle
                                  className=""
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                  }}>
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"></i>
                                </Dropdown.Toggle> */}
                                <Dropdown.Toggle
                                  as={React.forwardRef(
                                    ({ children, onClick }, ref) => (
                                      <button
                                        type="button"
                                        ref={ref}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          onClick(e);
                                        }}
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        {children}
                                      </button>
                                    )
                                  )}
                                >
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="text-center">
                                  <Dropdown.Item
                                    onClick={(e) =>
                                      handleVectorize(item?.ks_event_id, e)
                                    }
                                  >
                                    Vectorize
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    onClick={(e) =>
                                      handleDeleteClick(item?.ks_event_id, e)
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                          <td>{item?.status || "N/A"}</td>
                        </tr>
                        {item?.vectorizations.map(
                          (vectorization, vectorIndex) => (
                            <tr key={`${index}-vectorization-${vectorIndex}`}>
                              <td
                                className={
                                  vectorization?.saves?.[0]?.active === true &&
                                  "bg-secondary"
                                }
                                colSpan={3}
                              ></td>
                              <td
                                className={
                                  vectorization?.saves?.[0]?.active === true &&
                                  "bg-secondary"
                                }
                              >
                                {vectorization?.vector_filename
                                  ? vectorization?.vector_filename
                                  : "N/A"}
                              </td>
                              <td
                                className={
                                  vectorization?.saves?.[0]?.active === true &&
                                  "bg-secondary"
                                }
                                style={{ width: "10rem" }}
                              >
                                {formattedDate(vectorization?.event_date) ||
                                  "N/A"}
                              </td>
                              <td
                                className={
                                  vectorization?.saves?.[0]?.active === true &&
                                  "bg-secondary"
                                }
                                colSpan={2}
                              >
                                {vectorization?.saves?.length > 0 ? "" : "N/A"}
                              </td>
                              <td
                                className={
                                  vectorization?.saves?.[0]?.active === true &&
                                  "bg-secondary"
                                }
                              >
                                <div
                                  className="col d-flex justify-content-center align-items-center gap-2 h-100"
                                  style={{ minHeight: "74px" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      as={React.forwardRef(
                                        ({ children, onClick }, ref) => (
                                          <button
                                            type="button"
                                            ref={ref}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              onClick(e);
                                            }}
                                            style={{
                                              border: "none",
                                              background: "transparent",
                                            }}
                                          >
                                            {children}
                                          </button>
                                        )
                                      )}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="text-center">
                                      <Dropdown.Item
                                        onClick={(e) =>
                                          handleSave(
                                            item?.vectorizations[0]
                                              ?.ks_event_id,
                                            e
                                          )
                                        }
                                      >
                                        Save
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                      <Dropdown.Item
                                        onClick={(e) =>
                                          handleDeleteClick(
                                            item?.vectorizations[0]
                                              ?.ks_event_id,
                                            e
                                          )
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                              <td>
                                {vectorization?.status
                                  ? vectorization?.status
                                  : "N/A"}
                              </td>
                            </tr>
                          )
                        )}
                        {item.vectorizations.map((vectorization, vectorIndex) =>
                          vectorization?.saves.map((save, saveIndex) => (
                            <React.Fragment key={`${index}-save-${saveIndex}`}>
                              <tr>
                                <td
                                  className={
                                    save?.active === true && "bg-secondary"
                                  }
                                  colSpan={5}
                                ></td>
                                <td
                                  className={
                                    save?.active === true && "bg-secondary"
                                  }
                                >
                                  {save?.pinecone_namespace}
                                </td>
                                <td
                                  className={
                                    save?.active === true && "bg-secondary"
                                  }
                                  style={{ width: "10rem" }}
                                >
                                  {formattedDate(save?.event_date)}
                                </td>

                                <td
                                  className={
                                    save?.active === true && "bg-secondary"
                                  }
                                >
                                  <div
                                    className="col d-flex justify-content-center align-items-center gap-2 h-100"
                                    style={{ minHeight: "74px" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        as={React.forwardRef(
                                          ({ children, onClick }, ref) => (
                                            <button
                                              type="button"
                                              ref={ref}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                onClick(e);
                                              }}
                                              style={{
                                                border: "none",
                                                background: "transparent",
                                              }}
                                            >
                                              {children}
                                            </button>
                                          )
                                        )}
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="text-center">
                                        {save?.active === false && (
                                          <Dropdown.Item
                                            onClick={(e) =>
                                              handleMakeActive(
                                                vectorization?.saves[0]
                                                  ?.ks_event_id,
                                                e
                                              )
                                            }
                                          >
                                            Make Active
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                          onClick={(e) =>
                                            handleDeleteClick(
                                              vectorization?.saves[0]
                                                ?.ks_event_id,
                                              e
                                            )
                                          }
                                        >
                                          {" "}
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                                <td>{save?.status || "N/A"}</td>
                              </tr>
                              <tr>
                                <td colSpan={1}></td>
                                <td colSpan={1}></td>
                                <td colSpan={1}></td>
                                <td colSpan={1}></td>
                                <td colSpan={1}></td>

                                <td colSpan={2}>
                                  <div style={{ padding: "5px 10px" }}>
                                    {save?.threads !== undefined &&
                                      save?.threads !== 0 && (
                                        <p>
                                          Threads:{" "}
                                          {save?.threads?.toLocaleString()}
                                        </p>
                                      )}
                                    {save?.thread_blocks !== undefined &&
                                      save?.thread_blocks !== 0 && (
                                        <p>
                                          Thread Blocks:{" "}
                                          {save?.thread_blocks?.toLocaleString()}
                                        </p>
                                      )}
                                    {save?.web_page_blocks !== undefined &&
                                      save?.web_page_blocks !== 0 && (
                                        <p>
                                          Web Page Blocks:{" "}
                                          {save?.web_page_blocks?.toLocaleString()}
                                        </p>
                                      )}

                                    {save?.pdf_file_blocks !== undefined &&
                                      save?.pdf_file_blocks !== 0 && (
                                        <p>
                                          PDF File Blocks:{" "}
                                          {save?.pdf_file_blocks?.toLocaleString()}
                                        </p>
                                      )}
                                    {save?.articles !== undefined &&
                                      save?.articles !== 0 && (
                                        <p>
                                          Articles:{" "}
                                          {save?.articles?.toLocaleString()}
                                        </p>
                                      )}
                                    {save?.article_blocks !== undefined &&
                                      save?.article_blocks !== 0 && (
                                        <p>
                                          Article Blocks:{" "}
                                          {save?.article_blocks?.toLocaleString()}
                                        </p>
                                      )}
                                    {save?.stats && (
                                      <>
                                        {save.stats.web_pages !== undefined &&
                                          save.stats.web_pages !== 0 && (
                                            <p>
                                              Web Pages:{" "}
                                              {save.stats.web_pages?.toLocaleString()}
                                            </p>
                                          )}
                                        {save.stats.web_page_blocks !==
                                          undefined &&
                                          save.stats.web_page_blocks !== 0 && (
                                            <p>
                                              Web Page Blocks:{" "}
                                              {save.stats.web_page_blocks?.toLocaleString()}
                                            </p>
                                          )}
                                        {save.stats.pdf_files !== undefined &&
                                          save.stats.pdf_files !== 0 && (
                                            <p>
                                              PDF Files:{" "}
                                              {save.stats.pdf_files?.toLocaleString()}
                                            </p>
                                          )}
                                        {save.stats.pdf_file_blocks !==
                                          undefined &&
                                          save.stats.pdf_file_blocks !== 0 && (
                                            <p>
                                              PDF File Blocks:{" "}
                                              {save.stats.pdf_file_blocks?.toLocaleString()}
                                            </p>
                                          )}
                                        {save.stats.articles !== undefined &&
                                          save.stats.articles !== 0 && (
                                            <p>
                                              Articles:{" "}
                                              {save.stats.articles?.toLocaleString()}
                                            </p>
                                          )}
                                        {save.stats.article_blocks !==
                                          undefined &&
                                          save.stats.article_blocks !== 0 && (
                                            <p>
                                              Article Blocks:{" "}
                                              {save.stats.article_blocks?.toLocaleString()}
                                            </p>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </td>

                                <td colSpan={1}></td>
                              </tr>
                            </React.Fragment>
                          ))
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
            <DeleteConfirmationModal
              show={showModal}
              handleClose={handleCloseModal}
              handleDelete={() => handleDelete(ksEventIdToDelete)}
            />
            <ToastContainer />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default TableForVersions;
