import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap"; // Ensure Modal and Button are imported
import { config } from "../../config";
import { AppContext } from "../../AppContext/Context";
import { useNavigate, useParams } from "react-router-dom";
import useHandleUnauthorized from "../../hooks/useHandleUnauthorized/UseHandleUnauthorized";
import axios from "axios";
import Loader from "../loader";
import SweetLayout from "../../Layout/SweetLayout";

const ResponseDetailPage = () => {
  const { isLoading, getIdTokenClaims } = useAuth0();
  const { setIsLoad, isLoad } = useContext(AppContext);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const handleUnauthorized = useHandleUnauthorized();
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const fetchData = async () => {
    if (!isLoading) {
      setIsLoad(true);
      const endpoint = `${config.baseURL}/admin/v1/response/${id}`;
      try {
        const getIdToken = await getIdTokenClaims();
        const idToken = getIdToken.__raw;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("API request error:", error.message);
        handleUnauthorized(error);
      } finally {
        setIsLoad(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [isLoading, id, getIdTokenClaims, handleUnauthorized, setIsLoad]);

  const cleanedResponseText = data?.response_text
    ? data.response_text.replace(/<\/?[^>]+(>|$)/g, "")
    : "";

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <SweetLayout>
    <div>
      {(isLoading || !data) && (
        <div>
          {isLoad && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100%",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}

      <div
        onClick={() => nav(-1)}
        className="fe-5 fw-bold"
        style={{ cursor: "pointer" }}
      >
        <span className="bi bi-chevron-left"></span> Back
      </div>
      <h1>Response Detail</h1>
      <Table responsive striped bordered hover className="x-auto">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Response ID</td>
            <td>{data?.response_id ? data?.response_id : "N/A"}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{data?.date ? data?.date : "N/A"}</td>
          </tr>
          <tr>
            <td>Channel ID</td>
            <td>{data?.channel_id ? data?.channel_id : "N/A"}</td>
          </tr>
          <tr>
            <td>Channel Name</td>
            <td>{data?.channel_name ? data?.channel_name : "N/A"}</td>
          </tr>
          <tr>
            <td>Likes</td>
            <td>{data?.likes}</td>
          </tr>
          <tr>
            <td>Dislikes</td>
            <td>{data?.dislikes}</td>
          </tr>
          <tr>
            <td>Question</td>
            <td>{data?.question ? data?.question : "N/A"}</td>
          </tr>
          <tr>
            <td>User Feedback</td>
            <td>{data?.user_feedback ? data?.user_feedback : "N/A"}</td>
          </tr>
          <tr>
            <td>User</td>
            <td>{data?.user ? data?.user : "N/A"}</td>
          </tr>
          <tr>
            <td>Response Text</td>
            <td>{cleanedResponseText ? cleanedResponseText : "N/A"}</td>
          </tr>
          <tr>
            <td>Conversion ID</td>
            <td>{data?.conversation_id ? data?.conversation_id : "N/A"}</td>
          </tr>
          <tr>
            <td>Generator ID</td>
            <td>{data?.generator_id ? data?.generator_id : "N/A"}</td>
          </tr>{" "}
          <tr>
            <td>Embedding Source Matches</td>
            <td>
              <Button variant="primary" onClick={handleModalOpen}>
                View Detail
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Embedding Source Matches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data &&
          data.embedding_source_matches &&
          data.embedding_source_matches.length > 0 ? (
            <Table responsive striped bordered hover className="x-auto">
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Title</th>
                  <th>Score</th>
                  <th>Namespace</th>
                  <th>Section</th>
                  <th>Tokens</th>
                  <th>Timestamp</th>
                  <th>Ks ID</th>
                  <th>Ks Name</th>
                  <th>Ks Type</th>
                  <th>Tags</th>
                  <th>Likes</th>
                  <th>Dislikes</th>
                  <th>Uses</th>
                  <th>User Feedback</th>
                </tr>
              </thead>
              <tbody>
                {data.embedding_source_matches.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.title}</td>
                    <td>{item?.score}</td>
                    <td>{item?.namespace}</td>
                    <td>{item?.section}</td>
                    <td>{item?.tokens}</td>
                    <td>
                      {new Date(
                        parseFloat(item?.timestamp) * 1000
                      ).toLocaleString()}
                    </td>
                    <td>{item?.ks_id}</td>
                    <td>{item?.ks_name}</td>
                    <td>{item?.ks_type}</td>
                    <td>{item?.tags.join(", ")}</td>
                    <td>{item?.likes}</td>
                    <td>{item?.dislikes}</td>
                    <td>{item?.uses}</td>
                    <td>
                      {item?.user_feedback ? item?.user_feedback : "None"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              className="border border-dark d-flex align-items-center justify-content-center fw-bold"
              style={{ minHeight: "300px" }}
            >
              No embedding source matches found
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
    </SweetLayout>
  );
};

export default ResponseDetailPage;
