import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Table } from 'react-bootstrap';

function ActivitiesChart(props) {
    const { range, start_date, end_date, channel, setLoading, selectedAccount } = props;
    const { user } = useAuth0();
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

   
    const getActivities = async (type, lookback, channel, startDate, endDate) => {
        try {
            setLoading(true);
            const getData = await props.getReports(type, lookback, null, channel, startDate, endDate);
            if(getData.error) {
                setErrorMessage(getData.error);
                setData([]);
            }else {
                setErrorMessage(null);
                setData(getData);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };


    useEffect(() => {
        if (user && selectedAccount?.value !== null) {
        getActivities("activities_table", range, channel, start_date, end_date);
        }
    }, [range, channel, start_date, end_date, selectedAccount]); // Re-fetch data when the range changes


    const renderTable = () => {
        if (!data || data.length === 0) {
            return null; // Return null if data is not defined or empty
        }

        const metrics = [
            { name: 'Response Likes', key: 'response_likes' },
            { name: 'Response Dislikes', key: 'response_dislikes' },
            { name: 'Response Text Count', key: 'response_text_count' },
            { name: 'Retrieval Likes', key: 'retrieval_likes' },
            { name: 'Retrieval Dislikes', key: 'retrieval_dislikes' },
            { name: 'Retrieval Text Count', key: 'retrieval_text_count' },
        ];

        return (
            <div className="table-box">
        <Table hover>
                <thead>
                    <tr>
                        <th>User ID</th>
                        {metrics.map(metric => (
                            <th key={metric.key}>{metric.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr key={item.user_id}>
                            <td>{item.user_id}</td>
                            {metrics.map(metric => (
                                <td key={metric.key}>{item[metric.key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
        );
    };

    return (
        <div>
            <h2>Activities</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div style={{ overflowX: 'auto' }}>
                {renderTable()}
            </div>
        </div>
    );
}

export default ActivitiesChart;
