import React from "react";
import { Pagination } from "react-bootstrap";

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];

    // If totalPages <= 5, render all pages
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Render first few pages, ellipsis, and last page
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(currentPage + 2, totalPages);

      if (startPage > 1) {
        items.push(
          <Pagination.First key="first" onClick={() => handlePageChange(1)} />
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(
          <Pagination.Ellipsis key="ellipsis" disabled />
        );
        items.push(
          <Pagination.Item
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  return (
    <Pagination
      className="justify-content-end mt-2"
      style={{ maxWidth: "100%", overflowX: "auto" }}
      aria-label="Page navigation example"
    >
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {renderPaginationItems()}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default CustomPagination;
